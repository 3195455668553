import {
  INIT, SUCCESS, LOADING, ERROR,
} from '../../utils/constants';

/**
 * Import All the action type constants
 */

import {
  VALIDATE_SITE_CODE,
  VALIDATE_SITE_CODE_SUCCESS,
  VALIDATE_SITE_CODE_ERROR,
  GENERATE_SITE_KEY,
  GENERATE_SITE_KEY_SUCCESS,
  GENERATE_SITE_KEY_ERROR,
  VALIDATE_SITE_CODE_CUSTOMER,
  VALIDATE_SITE_CODE_SUCCESS_CUSTOMER,
  VALIDATE_SITE_CODE_SUCCESS_EMERSON,
  VALIDATE_SITE_CODE_EMERSON
} from './constants';

/**
 * Initiliazed initila state of the store
 */
const initialState = {
  phase: INIT,
  siteCode: null,
  siteCodeCustomer: null,
  siteCodeEmerson: null,
  siteKey: null,
  error: null,
};

/**
 * User Reducer
 * @param {Object} state
 * @param {Object} action
 */

export function crypKeyStore(state, action) {
  /**
   * initilize the initial sate of the store
   */
  if (typeof state === 'undefined') {
    return initialState
  }
  /**
   * action type cases
   */
  switch (action.type) {
    case VALIDATE_SITE_CODE: case GENERATE_SITE_KEY: case VALIDATE_SITE_CODE_CUSTOMER: case VALIDATE_SITE_CODE_EMERSON:
      return {
        ...state,
        phase: LOADING,
      };
    case VALIDATE_SITE_CODE_SUCCESS:
      return {
        ...state,
        phase: SUCCESS,
        siteCode: action.data,
        error: null,
      };
    case VALIDATE_SITE_CODE_SUCCESS_CUSTOMER:
      return {
        ...state,
        phase: SUCCESS,
        siteCodeCustomer: action.data,
        error: null,
      };
    case VALIDATE_SITE_CODE_SUCCESS_EMERSON:
      return {
        ...state,
        phase: SUCCESS,
        siteCodeEmerson: action.data,
        error: null,
      };
    case VALIDATE_SITE_CODE_ERROR: case GENERATE_SITE_KEY_ERROR:
      return {
        ...state,
        phase: ERROR,
        error: action.error
      };

    case GENERATE_SITE_KEY_SUCCESS:
      return {
        ...state,
        phase: SUCCESS,
        siteKey: action.data,
        error: null,
      };
    default:
      return state;
  }
}

/**
 * validate site code Method with action type
 * @param {String} email
 */

export const validateSiteCode = payload => ({
  type: VALIDATE_SITE_CODE,
  payload
})

/**
 * validate site code Method with action type
 * @param {String} email
 */

export const validateSiteCodeCustomer = payload => ({
  type: VALIDATE_SITE_CODE_CUSTOMER,
  payload
})

/**
 * validate site code Method with action type
 * @param {String} email
 */

export const validateSiteCodeEmerson = payload => ({
  type: VALIDATE_SITE_CODE_EMERSON,
  payload
})

/**
 * generate site key method with action type
 * @param {*} payload 
 */
export const generateSiteKey = payload => ({
  type: GENERATE_SITE_KEY,
  payload
})
