/**
 * Constant For Site Key Report
 */
const GET_SITE_KEY_REPORT = 'GET_SITE_KEY_REPORT';
const GET_SITE_KEY_REPORT_SUCCESS = 'GET_SITE_KEY_REPORT_SUCCESS';
const GET_SITE_KEY_REPORT_ERROR = 'GET_SITE_KEY_REPORT_ERROR';

/**
 * Constant for Good Will Report
 */
const GET_GOODWILL_REPORT = 'GET_GOODWILL_REPORT';
const GET_GOODWILL_REPORT_SUCCESS = 'GET_GOODWILL_REPORT_SUCCESS';
const GET_GOODWILL_REPORT_ERROR = 'GET_GOODWILL_REPORT_ERROR';

/**
 * Constant to updtae the quantity
 */
const UPDATE_QUANTITY = 'UPDATE_QUANTITY';
const UPDATE_QUANTITY_SUCCESS = 'UPDATE_QUANTITY_SUCCESS';
const UPDATE_QUANTITY_ERROR = 'UPDATE_QUANTITY_ERROR';

/**
 * Constants to clear the good will report
 */
const CLEAR_GOODWILL_REPORT = 'CLEAR_GOODWILL_REPORT';
const CLEAR_GOODWILL_REPORT_SUCCESS = 'CLEAR_GOODWILL_REPORT_SUCCESS';
const CLEAR_GOODWILL_REPORT_ERROR = 'CLEAR_GOODWILL_REPORT_ERROR';

/**
 * Constant For Site Key Report
 */
const CLEAR_SITE_KEY_REPORT = 'CLEAR_SITE_KEY_REPORT';
const CLEAR_SITE_KEY_REPORT_SUCCESS = 'CLEAR_SITE_KEY_REPORT_SUCCESS';
const CLEAR_SITE_KEY_REPORT_ERROR = 'CLEAR_SITE_KEY_REPORT_ERROR';

export { 
    GET_GOODWILL_REPORT,
    GET_GOODWILL_REPORT_ERROR,
    GET_GOODWILL_REPORT_SUCCESS,
    GET_SITE_KEY_REPORT,
    GET_SITE_KEY_REPORT_ERROR,
    GET_SITE_KEY_REPORT_SUCCESS,
    UPDATE_QUANTITY,
    UPDATE_QUANTITY_SUCCESS,
    UPDATE_QUANTITY_ERROR,
    CLEAR_GOODWILL_REPORT,
    CLEAR_GOODWILL_REPORT_SUCCESS,
    CLEAR_GOODWILL_REPORT_ERROR,
    CLEAR_SITE_KEY_REPORT,
    CLEAR_SITE_KEY_REPORT_SUCCESS,
    CLEAR_SITE_KEY_REPORT_ERROR
}