/**
 * import CustomTable component
 */

import Customtable from './Customtable';

/**
 * Export Custome table component
 */

export default Customtable;