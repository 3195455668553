import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

/**
 * Table Component
 */
const Customtable = ({ data, page, sizePerPage, onTableChange, totalSize, columns, hideSizePerPage, keyField }) => {
  return (
    <BootstrapTable
      remote
      keyField={keyField}
      data={data}
      columns={columns}
      pagination={paginationFactory({ page, sizePerPage, totalSize , hideSizePerPage})}
      onTableChange={onTableChange}
      noDataIndication="No Record Found"
    />
    
  );
};

export default Customtable;