/**
 * Initilized the Initila Constants for Crypkey
 * 
 */

 
 /**
  * Create constants for product type
  */

const ProductType = {
    PROLINKIII_BASIC: "3",
    PROLINKIII_PROFESSIONAL: "2",
    PROLINKII: "1"
}

/**
 * Create const for ProLinkIIIBasicOptions
 */

const ProLinkIIIBasicOptions = {
    STANDARD: "1",
    MODBUS: "2"
}

/**
 * Create const for ProLinkIIIProfessionalOptions
 */

const ProLinkIIIProfessionalOptions = {
    STANDARD: "1",
    EMERSON_SERVICE: "10",
    MODBUS: "100"
}

/**
 * Create const for ProLinkIIOptions
 */

const ProLinkIIOptions = {
    STANDARD_OPTION: "1",
    ENABLE_SECURE_MODE_1_OPTION: "2",
    MODBUS_OPC_EXTERNAL_USE_OPTION: "4",
    HART_OPC_EXTERNAL_USE_OPTION: "8",
    MULTI_DEVICE_DATA_LOGGER_OPTION: "10",
    ENABLE_MODBUS_TAB_MODE_2_OPTION: "20"
}

/**
 * Create const for RestrictionType
 */

const RestrictionType = {
    None: "0",
    Time: "1",
    Runs: "2"
}

/**
 * Create const for LicenseType
 */

const LicenseType = {
    Fixed: "0",
    Floating: "1"
}

/**
 * Create const for ErrorCodes
 */

const ErrorCodes = {
    SITE_CODE_OK: "0",
    KILL_CODE_OK: "0",
    SITE_CODE_SIZE_CHECK_FAIL: "-1",
    SITE_CODE_ENTRY_CRC_FAIL: "-2",
    SITE_KEY_VERSION_OUTDATED: "-3",
    SITE_CODE_VERSION_OUTDATED: "-4",
    SITE_CODE_FROM_FOREIGN_COMPANY: "-5",
    KILL_CODE_SIZE_CHECK_FAIL: "-6",
    KILL_CODE_CRC_FAIL: "-7",
    KILL_CODE_VERSION_TOO_NEW: "-8",
    KILL_CODE_VERSION_OUTDATED: "-9",
    KILL_CODE_WRONG_COMPANY: "-10",
    EASY_LICENSE_NOT_AVAILABLE: "-11",
    SITE_CODE_FROM_DISALLOWED_PRODUCT: "-12",
    SITE_KEY_ENCRYP_FAIL: "-13"
}

/**
 * Create const for Levels
 */

const Levels = {
    STANDARD_OPTION: "0",
    ENHANCED_LEVEL1: "1",
    ENHANCED_LEVEL2: "2",
    SERVICE_LEVEL: "3"
}

/**
 * Create const for Options
 */

const Options = {
    STANDARD_OPTION: "1",
    ENABLE_SECURE_MODE_1_OPTION: "2",
    MODBUS_OPC_EXTERNAL_USE_OPTION: "4",
    HART_OPC_EXTERNAL_USE_OPTION: "8",
    MULTI_DEVICE_DATA_LOGGER_OPTION: "10",
    ENABLE_MODBUS_TAB_MODE_2_OPTION: "20",
    SERVICE_lICENSE: "3"
}

/**
 * Create const for NumberOfCopies
 */

const NumberOfCopies = {
    STANDARD_OPTION: "1"
}

/**
 * Create const for RestrictionCount
 */

const RestrictionCount = {
    STANDARD_OPTION: "-1"
}

/**
 * Export all the constants
 * ProductType
 * ProLinkIIIBasicOptions
 * ProLinkIIIProfessionalOptions
 * ProLinkIIOptions
 * RestrictionType
 * LicenseType
 * ErrorCodes
 * Levels
 * Options
 * NumberOfCopies
 * RestrictionCount
 */

export {
    ProductType,
    ProLinkIIIBasicOptions,
    ProLinkIIIProfessionalOptions,
    ProLinkIIOptions,
    RestrictionType,
    LicenseType,
    ErrorCodes,
    Levels,
    Options,
    NumberOfCopies,
    RestrictionCount
}