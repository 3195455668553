/**
 * Import React Library and Required Files
 */

import { connect } from "react-redux";
import AdvancedActivation from "./AdvancedActivation";

/**
 * Connect the Store State to the components
 * Used mapStateToprops and connect the component to the react redux store
 * @param {*} state
 */

const mapStateToProps = state => state;
export default connect(mapStateToProps)(AdvancedActivation);
