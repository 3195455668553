import Error from "./Error";
import { connect } from "react-redux";
import { getTranslation,getSotwareType } from "../../store/translation";
import { getReasons } from "../../store/serialnumber";
import { getAuth } from "../../store/auth";

/**
 * Connect the Store State to the components
 * @param {*} state
 */
const mapStateToProps = state => state;

/**
 *  Dispatch the function for Api Calls
 */

const mapDispatchToProps = {
  getTranslation: payload => getTranslation(payload),
  getReasons: payload => getReasons(payload),
  getAuth: payload => getAuth(payload),
  getSotwareType: payload => getSotwareType(payload)
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Error);


