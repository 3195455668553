import React, { Component } from "react";
import { Headers } from "../../components";
import PropTypes from "prop-types";
import SoftwareActivation from "../SoftwareActivation";
import SerialNumber from "../SerialNumber";
import AdvanceActivation from "../AdvanceActivation";
import Reports from "../Reports";
import {
  Container,
  Row,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  FormGroup,
  Form,
  Col,
  Input,
  Label
} from "reactstrap";
import classnames from "classnames";

/**
 * Main Container which is controlling all the container and components
 * All four conatier are called here for fours tabs
 * Tab toggling fuctionality are implemented here
 *
 */

class Home extends Component {
  /**
   * Constructors initilization
   * @param {*} props
   */

  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: "1",
      serialKey: null,
      appname: "",
      isAdmin: false,
      isSales: false,
      header: false,
      language: "en_US"
    };
  }

  /**
   * toggle function is used to hande the tab changes events and trigger active active tab on click
   * @param {*} tab on click the value of the tab
   */

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  onLanChange = (lang) =>{
    this.setState({
      language: lang
    });
  }

  /**
   * Toggelandfill fucntion is used to when user click on the activate button in serail number search tab
   * Its populate the data tpo the first tab with the serial number
   */

  toggleAndFill = (tab, key, appname) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
        serialKey: key,
        appname: appname
      });
    }
  };

  setKey = () => {
    this.setState({
      serialKey: null
    });
  };

  /**
   * handlechange function is used to handel the input change in value
   */

  handleChange = e => {
    const tab = e.target.value;
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  };

  /**
   * Declare Types of the varaiables
   */

  static propTypes = {
    getTranslation: PropTypes.func.isRequired,
    getReasons: PropTypes.func.isRequired,
    getSotwareType: PropTypes.func.isRequired
  };

  /**
   * React Life cycle is used mount the data after immidiate doms rendered
   */

  componentDidMount() {
    const { getTranslation, getReasons, getSotwareType } = this.props;
    const { language } = this.state;
    if (window.result) {
        if (window.result.role === "Flow Admin Role") {
          this.setState({
            isAdmin: true,
            isSales: false,
            activeTab:"3"
          });
        }
        if (window.result.role === "Flow Sales Role") {
          this.setState({
            isAdmin: false,
            isSales: true,
            activeTab:"3"
          });
        }
      } else {
        this.setState({
          isAdmin: false,
          isSales: false
        });
      }
    
    getTranslation({ language : language});
    getReasons({ language : language});
    getSotwareType({ language : language});
  }

  componentDidUpdate(prevProps, prevState) {
    const {
     getTranslation,
      getReasons,getSotwareType
    } = this.props;
    const { language } = this.state;
    if(prevState.language !== language){
      getTranslation({ language : language});
      getReasons({ language : language});
      getSotwareType({ language : language});
    }
  }

  /**
   * Render the doms elements
   */

  render() {
    const { serialKey, appname, isAdmin, isSales } = this.state;
    const {
      translation: { data }
    } = this.props;
    return (
      <>
        <Headers languageChange={this.onLanChange} error = {false} />
        <Container>
          <Row className="heading-row" data-test="home">
            <p className="main-head">
              {data !== null
                ? data.heading
                : "Activate ProLink configuration software."}
            </p>
          </Row>
        </Container>
        <Row className="background-strip hidden-xs"></Row>

        <Container className="pad-tab-container">
          <Form autoComplete="off" className="tab-mobile-screen">
            <FormGroup row>
              <Col sm={4}>
                <Label
                  for="exampleSelect"
                  className="custom-label select-drop-label-main"
                />
                <Input
                  type="select"
                  name="tab-mobile"
                  id="exampleSelect"
                  className="form-dropdown-text"
                  placeholder="Select Active Tab"
                  onChange={this.handleChange}
                  value={this.state.activeTab}
                >
                  <option value={"1"}>
                    {data !== null
                      ? data.software_activation_tab
                      : "Software Activation"}
                  </option>
                  <option value={"2"}>
                    {data !== null
                      ? data.search_serial_num_tab
                      : "Search For Serial Number"}
                  </option>
                  {isAdmin || isSales ? (
                    <option value={"3"}>
                      {data !== null
                        ? data.advanced_activation_tab
                        : "ADVANCED ACTIVATION"}
                    </option>
                  ) : (
                    ""
                  )}
                  {isAdmin ? (
                    <option value={"4"}>
                      {data !== null ? data.reports_tab : "Reports"}
                    </option>
                  ) : (
                    ""
                  )}
                </Input>
              </Col>
            </FormGroup>
          </Form>
          <Nav tabs className="desktop-tab">
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === "1" })}
                onClick={() => {
                  this.toggle("1");
                }}
              >
                <h2>
                  {data !== null
                    ? data.software_activation_tab
                    : "Software Activation"}
                </h2>
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === "2" })}
                onClick={() => {
                  this.toggle("2");
                }}
                data-test={"tab2"}
              >
                <h2>
                  {data !== null
                    ? data.search_serial_num_tab
                    : "Search For Serial Number"}
                </h2>
              </NavLink>
            </NavItem>

            {isAdmin || isSales ? (
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === "3"
                  })}
                  onClick={() => {
                    this.toggle("3");
                  }}
                  data-test={"tab3"}
                >
                  <h2>
                    {data !== null
                      ? data.advanced_activation_tab
                      : "ADVANCED ACTIVATION"}
                  </h2>
                </NavLink>
              </NavItem>
            ) : (
              ""
            )}
            {isAdmin ? (
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === "4"
                  })}
                  onClick={() => {
                    this.toggle("4");
                  }}
                  data-test={"tab4"}
                >
                  <h2>{data !== null ? data.reports_tab : "Reports"}</h2>
                </NavLink>
              </NavItem>
            ) : (
              ""
            )}
          </Nav>
          <TabContent activeTab={this.state.activeTab}>
            <TabPane tabId="1">
              <SoftwareActivation
                onTabChange={this.toggle}
                serialKey={serialKey}
                appname={appname}
                setSerialKey={this.setKey}
              />
            </TabPane>
            <TabPane tabId="2">
              <SerialNumber onTabChange={this.toggleAndFill} />
            </TabPane>
            {isAdmin || isSales ? (
              <TabPane tabId="3">
                <AdvanceActivation />
              </TabPane>
            ) : (
              ""
            )}
            {isAdmin ? (
              <TabPane tabId="4">
                <Reports />
              </TabPane>
            ) : (
              ""
            )}
          </TabContent>
        </Container>
      </>
    );
  }
}

export default Home;
