import {
  INIT, SUCCESS, LOADING, ERROR,
} from '../../utils/constants';
import {
  VALIDATE_SERIAL_SOFTWARE,
  VALIDATE_SERIAL_SOFTWARE_SUCCESS,
  VALIDATE_SERIAL, 
  VALIDATE_SERIAL_SUCCESS, 
  VALIDATE_SERIAL_ERROR
} from './constants';

/**
 * Initiliazed initila state of the store
 */
const initialState = {
  phase: INIT,
  email: null,
  serial: null,
  serialsoft: null,
  site: null,
  error: null,
};

/**
 * User Reducer
 * @param {Object} state
 * @param {Object} action
 */

export function userStore(state, action) {
 if (typeof state === 'undefined') {
    return initialState
  }
  switch (action.type) {
    case VALIDATE_SERIAL: case VALIDATE_SERIAL_SOFTWARE:
      return {
        ...state,
        phase: LOADING,
      };
    case VALIDATE_SERIAL_ERROR:
      return { 
        ...state, 
        phase: ERROR, 
        error: action.error 
      };
    case VALIDATE_SERIAL_SUCCESS:
      return {
        ...state,
        phase: SUCCESS,
        serial: action.data,
        error: null
      };
    case VALIDATE_SERIAL_SOFTWARE_SUCCESS:
      return {
        ...state,
        phase: SUCCESS,
        serialsoft: action.data,
        error: null
      };
    default:
      return state;
  }
}

export const validateSerialKey = payload => ({
  type: VALIDATE_SERIAL,
  payload
})
export const validateSerialKeySoftware = payload => ({
  type: VALIDATE_SERIAL_SOFTWARE,
  payload
})
