import React, { Component } from "react";
import {
  FormGroup,
  Label,
  Input,
  Col,
  Button,
  Row,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  Table,
  FormFeedback
} from "reactstrap";
import DatePicker from "react-datepicker2";
import { connect } from "react-redux";
import { getSiteKeyReport, updateQuantityNumber, clearSiteKeyReport } from "../../store/report";
import PropTypes from "prop-types";
import filterFactory, {
  textFilter,
  numberFilter,
  dateFilter,
  Comparator
} from "react-bootstrap-table2-filter";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import moment from "moment";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

let nameFilter;
let poFilter;
let waFilter;
let soFilter;
let serialNumberFilter;
let quantityFilter;
let applicationFilter;
let issueDateFilter;
let generatedDateFilter;
let generatedByFilter;
let notesFilter;

class SiteKeyReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formValues: {
        licensegeneratedate: "",
        licencegeneratedby: "",
        customername: "",
        purchasenumber: "",
        serialnumber: "",
        salesorder: null,
        issuedate: ""
      },
      status: {
        licencegeneratedby: null,
        customername: null,
        purchasenumber: null,
        serialnumber: null,
        salesorder: null,
        licensegeneratedate: null,
        issuedate: null
      },
      licensegeneratedate: null,
      issuedate: null,
      modal: false,
      row: null,
      quantity: "",
      quantityStatus: null,
      disabled: false
    };
    this.keyCount = 0;
  }

  getKey = () => {
    return this.keyCount++;
  }

  /**
   * Declare the Static Props Types
   */
  static propTypes = {
    clearSiteKeyReport: PropTypes.func.isRequired,
    getSiteKeyReport: PropTypes.func.isRequired,
    updateQuantityNumber: PropTypes.func.isRequired
  };

  /**
   * Handle the form Submission and validation
   */

  submitForm = () => {
    const formvalid = this.validateFields();
    const { getSiteKeyReport } = this.props;
    const body = this.createRequestBody();
    if (formvalid) {
      getSiteKeyReport(body);
      nameFilter("");
      serialNumberFilter("");
      quantityFilter("");
      applicationFilter("");
      issueDateFilter("");
      generatedDateFilter("");
      generatedByFilter("");
      notesFilter("");
      waFilter("")
      poFilter("");
      soFilter("");
      this.setState(() => ({ disabled: false }));
    }
  };

  /**
   * Clear the form fileds and reset to initial state
   */
  clearForm = () => {
    const { clearSiteKeyReport } = this.props;
    clearSiteKeyReport();
    this.setState({
      formValues: {
        licensegeneratedate: "",
        licencegeneratedby: "",
        customername: "",
        purchasenumber: "",
        serialnumber: "",
        salesorder: "",
        issuedate: ""
      },
      status: {
        licencegeneratedby: null,
        customername: null,
        purchasenumber: null,
        serialnumber: null,
        salesorder: null,
        licensegeneratedate: null,
        issuedate: null
      },
      licensegeneratedate: null,
      issuedate: null,
      modal: false,
      row: null,
      quantity: "",
      quantityStatus: null,
      disabled: true
    });
  };

  createRequestBody = () => {
    const { formValues } = this.state;
    const formatedDate = formValues.licensegeneratedate
      ? moment(formValues.licensegeneratedate).format("DD-MMM-YY")
      : "";
    const formatedDate1 = formValues.issuedate
      ? moment(formValues.issuedate).format("DD-MMM-YY")
      : "";
    const body = {
      license_generated_date: formatedDate,
      license_generated_by: formValues.licencegeneratedby.trim(),
      customer_name: formValues.customername.trim(),
      issued_date: formatedDate1,
      purchase_order_number: formValues.purchasenumber.trim(),
      serial_number: formValues.serialnumber.trim(),
      sales_order_number: formValues.salesorder === "" ? null : formValues.salesorder,
      match: this.props.selectedtype
    };
    return body;
  };

  /**
   * Validate the form if Selected Type is All
   */

  validateFields = () => {
    const { formValues } = this.state;
    let formvalid = false;
    let validStatus = {},
      count = 0;

    Object.keys(formValues).map(function(key, index) {
      if (formValues[key]) {
        count += 1;
      }
      return count;
    });
    if (count > 0) {
      validStatus = {
        licencegeneratedby: false,
        customername: false,
        purchasenumber: false,
        serialnumber: false,
        salesorder: false,
        licensegeneratedate: false,
        issuedate: false
      };
      formvalid = true;
    } else {
      validStatus = {
        licencegeneratedby: true,
        customername: true,
        purchasenumber: true,
        serialnumber: true,
        salesorder: true,
        issuedate: true,
        licensegeneratedate: true
      };
      toast.error("At least one field should be filled", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
    }
    this.setState({ status: validStatus });
    return formvalid;
  };

  handleChange = e => {
    const { formValues } = this.state;
    formValues[e.target.name] = e.target.value;
    this.setState(() => ({ formValues, disabled: true }));
    this.validateFields();
  };

  updateQuantity = e => {
    const number = e.target.value;
    if (/^([1-9][0-9]{0,2}|1000)$/.test(number)) {
      this.setState({ quantityStatus: false, quantity: number });
    } else {
      this.setState({ quantityStatus: true, quantity: number });
    }
  };
  componentDidUpdate(prevProps, prevState) {
    const { licensegeneratedate, issuedate, formValues } = this.state;
    const {
      getSiteKeyReport,
      reportStore: { updateQuantity }
    } = this.props;
    if (prevState.licensegeneratedate !== licensegeneratedate) {
      formValues.licensegeneratedate = licensegeneratedate
        ? licensegeneratedate.locale("en").format("MM/DD/YYYY")
        : "";
      this.setState({ formValues });
      this.validateFields();
    }
    if (prevState.issuedate !== issuedate) {
      formValues.issuedate = issuedate
        ? issuedate.locale("en").format("MM/DD/YYYY")
        : "";
      this.setState({ formValues });
      this.validateFields();
    }
    if (prevProps.reportStore.updateQuantity !== updateQuantity) {
      const body = this.createRequestBody();
      getSiteKeyReport(body);
    }
  }

  EditFiled = (cell, row) => {
    return (
      <span className="edit">
        <i className="fa fa-pencil-square-o fa-lg"></i>
      </span>
    );
  };

  closePopUp = () => {
    this.setState(prevState => ({
      modal: false,
      quantityStatus: false
    }));
  };

  submitPopUp = () => {
    const { quantity, row } = this.state;
    const { updateQuantityNumber } = this.props;
    if (/^(?:[1-9][0-9]{3}|[1-9][0-9]{2}|[1-9][0-9]|[1-9])$/.test(quantity)) {
      this.setState({ quantityStatus: false, modal: false });
      const body = {
        serial_number: row.SERIAL_NUMBER,
        license_quantity: quantity,
        app_name: row.APPLICATION_NAME
      };
      updateQuantityNumber(body);
    } else {
      this.setState({ quantityStatus: true });
    }
  };

  editPopUp = row => {
    this.setState(prevState => ({
      row: row,
      quantity: row.LICENSED_QTY,
      modal: true
    }));
  };

  render() {
    const {
      licensegeneratedate,
      issuedate,
      status,
      formValues,
      row,
      quantity,
      quantityStatus,
      disabled
    } = this.state;
    const {
      reportStore: { sitekeyreport },
      translation: { data }
    } = this.props;
    const options = {
      paginationSize: 5,
      hideSizePerPage: true
    };
    const columns = [
      {
        dataField: "CUSTOMER_NAME",
        text: data !== null ? data.customer_name_label : "",
        filter: textFilter({
          getFilter: filter => {
            nameFilter = filter;
          }
        })
      },
      {
        dataField: "EditField",
        text: data !== null ? data.edit_column : "",
        formatter: this.EditFiled,
        csvExport: false,
        events: {
          onClick: (e, column, columnIndex, row, rowIndex) => {
            this.editPopUp(row);
          }
        }
      },
      {
        dataField: "CUSTOMER_PO",
        text:  data !== null ? data.purchase_ord_num_drop_down : "",
        filter: textFilter({
          getFilter: filter => {
            poFilter = filter;
          }
        })
      },
      {
        dataField: "SALES_ORDER_NUMBER",
        text: data !== null ? data.sales_ord_num_drop_down : "",
        filter: textFilter({
          getFilter: filter => {
            soFilter = filter;
          }
        })
      },
      {
        dataField: "SERIAL_NUMBER",
        text: data !== null ? data.serial_num_label : "",
        filter: textFilter({
          getFilter: filter => {
            serialNumberFilter = filter;
          }
        })
      },
      {
        dataField: "LICENSED_QTY",
        text: data !== null ? data.no_of_avail_table_col : "",
        filter: numberFilter({
          getFilter: filter => {
            quantityFilter = filter;
          }
        }),
        headerStyle: () => {
          return { width: "170px" };
        }
      },
      {
        dataField: "APPLICATION_NAME",
        text: data !== null ? data.application_name : "",
        filter: textFilter({
          getFilter: filter => {
            applicationFilter = filter;
          }
        })
      },
      {
        dataField: "ISSUED_DATE",
        text: data !== null ? data.issued_date_label : "",
        filter: dateFilter({
          getFilter: filter => {
            issueDateFilter = filter;
          },
          comparators: [Comparator.GT, Comparator.LT],
        }),
        headerStyle: () => {
          return { width: "220px" };
        }
      },
      {
        dataField: "LICENSE_GEN_DATE",
        text: data !== null ? data.license_gen_date_label : "",
        filter: dateFilter({
          getFilter: filter => {
            generatedDateFilter = filter;
          },
          comparators: [Comparator.GT, Comparator.LT],
        }),
        headerStyle: () => {
          return { width: "220px" };
        }
      },
      {
        dataField: "LICENSE_GENERATED_BY",
        text: data !== null ? data.license_gen_by_label : "",
        filter: textFilter({
          getFilter: filter => {
            generatedByFilter = filter;
          }
        })
      },
      {
        dataField: "NOTES",
        text: data !== null ? data.notes_column : "",
        filter: textFilter({
          getFilter: filter => {
            notesFilter = filter;
          }
        })
      },
      {
        dataField: "WORLD_AREA",
        text: "WA",
        filter: textFilter({
          getFilter: filter => {
            waFilter = filter;
          }
        })
      }
    ];
    const MyExportCSV = props => {
      const handleClick = () => {
        props.onExport();
      };
      return sitekeyreport ? (
        <Col sm={3} className="pull-right export-csv">
          <Button
            className="btn-block primary-btn"
            type="button"
            onClick={handleClick}
            id={"export-csv"}
            disabled={disabled}
          >
            {data !== null ? data.export_to_csv+ " " : "Export to CSV "}
              <i className="fa fa-chevron-right hvr-icon" aria-hidden="true"></i>
          
          </Button>
        </Col>
      ) : (
        ""
      );
    };
    return (
      <>
        <Form autoComplete="off" data-test={'sitekeyreport'}>
          <FormGroup row>
            <Col sm={4}>
              <Label for="exampleEmail" className="custom-label">
                {data !== null
                  ? data.license_gen_date_label
                  : "License Generated Date"}
              </Label>
              <Input
                type="text"
                name="licensegeneratedate"
                id="licensegeneratedate"
                className="form-input-text"
                placeholder={
                  data !== null
                    ? data.license_gen_date_label
                    : "License Generated Date"
                }
                value={formValues.licensegeneratedate}
                onChange={this.handleChange}
                invalid={status.licensegeneratedate}
                valid={status.licensegeneratedate === false ? true : false}
              />
              <Label className="calender-icon">
                <DatePicker
                  timePicker={false}
                  value={licensegeneratedate}
                  onChange={value =>
                    this.setState({ licensegeneratedate: value })
                  }
                />
                <i className="fa fa-calendar fa-lg" aria-hidden="true"></i>
              </Label>
            </Col>
            <Col sm={4}>
              <Label for="exampleEmail" className="custom-label">
                {data !== null
                  ? data.license_gen_by_label
                  : "License Generated By"}
              </Label>

              <Input
                type="text"
                name="licencegeneratedby"
                id="licence-generated-by"
                className="form-input-text"
                placeholder={
                  data !== null
                    ? data.license_gen_by_label
                    : "License Generated By"
                }
                onChange={this.handleChange}
                invalid={status.licencegeneratedby}
                valid={status.licencegeneratedby === false ? true : false}
                value={formValues.licencegeneratedby}
              />
            </Col>
            <Col sm={4}>
              <Label for="exampleEmail" className="custom-label">
                {data !== null ? data.customer_name_label : "Customer Name"}
              </Label>
              <Input
                type="text"
                name="customername"
                id="customername"
                className="form-input-text"
                placeholder={
                  data !== null ? data.customer_name_label : "Customer Name"
                }
                onChange={this.handleChange}
                invalid={status.customername}
                valid={status.customername === false ? true : false}
                value={formValues.customername}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col sm={4}>
              <Label for="exampleEmail" className="custom-label">
                {data !== null
                  ? data.purchase_ord_num_drop_down
                  : "Purchase Order Number"}
              </Label>
              <Input
                type="text"
                name="purchasenumber"
                id="purchasenumber"
                className="form-input-text"
                placeholder="Purchase Order Number"
                onChange={this.handleChange}
                invalid={status.purchasenumber}
                valid={status.purchasenumber === false ? true : false}
                value={formValues.purchasenumber}
              />
            </Col>
            <Col sm={4}>
              <Label for="exampleEmail" className="custom-label">
                {data !== null ? data.issued_date_label : "Issued Date"}
              </Label>
              <Input
                type="text"
                name="issuedate"
                id="issuedate"
                className="form-input-text"
                placeholder={
                  data !== null ? data.issued_date_label : "Issued Date"
                }
                value={formValues.issuedate}
                onChange={this.handleChange}
                invalid={status.issuedate}
                valid={status.issuedate === false ? true : false}
              />
              <Label className="calender-icon">
                <DatePicker
                  timePicker={false}
                  value={issuedate}
                  onChange={value => this.setState({ issuedate: value })}
                />
                <i className="fa fa-calendar fa-lg" aria-hidden="true"></i>
              </Label>
            </Col>

            <Col sm={4}>
              <Label for="exampleEmail" className="custom-label">
                {data !== null ? data.serial_num_label : "Serial Number"}
              </Label>
              <Input
                type="text"
                name="serialnumber"
                id="serialnumber"
                className="form-input-text"
                placeholder={
                  data !== null ? data.serial_num_label : "Serial Number"
                }
                onChange={this.handleChange}
                invalid={status.serialnumber}
                valid={status.serialnumber === false ? true : false}
                value={formValues.serialnumber}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col sm={4}>
              <Label for="exampleEmail" className="custom-label">
                {data !== null
                  ? data.sales_ord_num_drop_down
                  : "Sales Order Number"}
              </Label>
              <Input
                type="text"
                name="salesorder"
                id="salesorder"
                className="form-input-text"
                placeholder={
                  data !== null
                    ? data.sales_ord_num_drop_down
                    : "Sales Order Number"
                }
                onChange={this.handleChange}
                invalid={status.salesorder}
                valid={status.salesorder === false ? true : false}
                value={formValues.salesorder}
              />
            </Col>
            <Col sm={{ size: 4 }} className={"top-margin-input"}>
              <Button
                className="btn-block primary-btn"
                type="button"
                onClick={this.submitForm}
                id={"basic-search"}
              >
                {data !== null ? data.search_btn : "Search"}
                  <i className="fa fa-chevron-right hvr-icon" aria-hidden="true"></i>
               
              </Button>
            </Col>
            <Col sm={{ size: 4 }} className={"top-margin-input"}>
              <Button
                className="btn-block secondary-btn"
                type="button"
                onClick={this.clearForm}
              >
                {data !== null ? data.clear_btn : ""}
                  <i className="fa fa-chevron-right  hvr-icon" aria-hidden="true"></i>
                
              </Button>
            </Col>
          </FormGroup>
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnVisibilityChange
            draggable
            pauseOnHover
          />
        </Form>
        <Row>
          <Col sm={{ size: 12 }}>
            <ToolkitProvider
              keyField={'key'+this.getKey}
              data={sitekeyreport !== null ? sitekeyreport : []}
              columns={columns}
              exportCSV={{
                onlyExportFiltered: true,
                exportAll: false,
                fileName: "Site-Key-Report.csv"
              }}
            >
              {props => (
                <div>
                  <MyExportCSV {...props.csvProps} />
                  <BootstrapTable
                    {...props.baseProps}
                    pagination={paginationFactory(options)}
                    filter={filterFactory()}
                    noDataIndication="No Record Found"
                  />
                </div>
              )}
            </ToolkitProvider>
          </Col>
        </Row>
        <Modal isOpen={this.state.modal} toggle={this.closePopUp} centered={true}>
          <ModalHeader toggle={this.closePopUp}>{data !== null ? data.pop_up_header_2: "Update License Quantity"}</ModalHeader>
          <ModalBody>
            <Table borderless className="update-table">
              <tbody>
                <tr>
                  <td className={"tdhead"}>
                    {data !== null
                      ? data.sales_ord_num_drop_down + ":"
                      : "Sales Order Number :"}
                  </td>
                  <td>{row !== null ? row.SALES_ORDER_NUMBER : ""}</td>
                </tr>
                <tr>
                  <td className={"tdhead"}>
                    {data !== null
                      ? data.purchase_ord_num_drop_down + ":"
                      : "Purchase Order Number :"}
                  </td>
                  <td>{row !== null ? row.CUSTOMER_PO : ""}</td>
                </tr>
                <tr>
                  <td className={"tdhead"}>
                    {data !== null
                      ? data.serial_num_label + ":"
                      : "Serial Number :"}
                  </td>
                  <td>{row !== null ? row.SERIAL_NUMBER : ""}</td>
                </tr>
                <tr>
                  <td className={"tdhead"}>
                    {data !== null
                      ? data.license_gen_date_label + ":"
                      : "License Generated Date :"}
                  </td>
                  <td>{row !== null ? row.LICENSE_GEN_DATE : ""}</td>
                </tr>
                <tr>
                  <td className={"tdhead"}>
                    {data !== null
                      ? data.license_gen_by_label + ":"
                      : "License Generated By :"}
                  </td>
                  <td>{row !== null ? row.LICENSE_GENERATED_BY : ""}</td>
                </tr>
                <tr>
                  <td className={"tdhead"}>
                    {data !== null ? data.site_code_label + ":" : "Site Code :"}
                  </td>
                  <td>{row !== null ? row.SITE_CODE : ""}</td>
                </tr>
                <tr>
                  <td className={"tdhead"}>
                    {data !== null
                      ? data.issued_reason_label + ":"
                      : "Issue Reason :"}
                  </td>
                  <td>{row !== null ? row.ISSUE_REASON : ""}</td>
                </tr>
                <tr>
                  <td className={"tdhead"}>
                    {data !== null
                      ? data.license_issued_by_table_col + ":"
                      : "License Issued By :"}
                  </td>
                  <td>{row !== null ? row.ISSUED_BY : ""}</td>
                </tr>
                <tr>
                  <td className={"tdhead"}>
                    {data !== null
                      ? data.customer_name_label + ":"
                      : "Customer Name :"}
                  </td>
                  <td>{row !== null ? row.CUSTOMER_NAME : ""}</td>
                </tr>
                <tr>
                  <td className={"tdhead"}>{data !== null ? data.license_qty_label + ":" : "License Qty :"}</td>
                  <td>
                    <Input
                      type="number"
                      className="text-filter"
                      onChange={this.updateQuantity}
                      value={quantity}
                      invalid={quantityStatus}
                      valid={quantityStatus === false ? true : false}
                    />
                    <FormFeedback invalid={quantityStatus}>
                    {data !== null ? data.activation_1_to_1000 : ""}
                    </FormFeedback>
                  </td>
                </tr>
              </tbody>
            </Table>
            <Col sm={6} className="pull-right">
            <Button onClick={this.submitPopUp} className="btn-block primary-btn">
              {data !== null ? data.update_btn+ " " : "Update "}
              <i className="fa fa-chevron-right  hvr-icon" aria-hidden="true"></i>
            </Button>
            </Col>
          </ModalBody>
        </Modal>
      </>
    );
  }
}

/**
 * Connect the Store State to the components
 * @param {*} state
 */
const mapStateToProps = state => state;

/**
 *  Dispatch the function for Api Calls
 */

const mapDispatchToProps = {
  getSiteKeyReport: payload => getSiteKeyReport(payload),
  updateQuantityNumber: payload => updateQuantityNumber(payload),
  clearSiteKeyReport: () => clearSiteKeyReport()
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SiteKeyReport);
