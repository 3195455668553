import axios from 'axios';
import { API_URL , METHOD } from '../../utils/constants';

/**
 * Make an Api call for authorization of the user
 * @param {*} credentials 
 */
export const authApi = payload =>
    axios({
      method: METHOD.POST,
      url: `${API_URL}/validateSession`,
      headers: { 'Content-Type': 'application/json'},
      data: JSON.stringify(payload),
    });