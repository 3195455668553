import SerialNumber from "./SerialNumber";
import { connect } from "react-redux";
import { searchData } from "../../store/serialnumber";

/**
 * Connect the Store State to the components
 * @param {*} state
 */
const mapStateToProps = state => state;

/**
 *  Dispatch the function for Api Calls
 */

const mapDispatchToProps = {
  searchData: payload => searchData(payload)
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SerialNumber);
