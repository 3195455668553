import {
    INIT, SUCCESS, LOADING, ERROR,
  } from '../../utils/constants';
  import {
    GET_TRANSLATON_DATA, GET_TRANSLATON_DATA_SUCCESS, GET_TRANSLATON_DATA_ERROR,
    GET_SOFTWARE_TYPE, GET_SOFTWARE_TYPE_ERROR, GET_SOFTWARE_TYPE_SUCCESS
  } from './constants';
  
  /**
   * Initiliazed initila state of the store
   */
  const initialState = {
    phase: INIT,
    data: null,
    software: null,
    error: null,
  };
  
  /**
   * User Reducer
   * @param {Object} state
   * @param {Object} action
   */

  export function translation(state, action) {
    
    /**
     * Set the state value
     */

    if (typeof state === 'undefined') {
      return initialState
    }

    /**
     * Case for action type
     * 
     */

    switch (action.type) {
      case GET_TRANSLATON_DATA: case GET_SOFTWARE_TYPE:
        return {
          ...state,
          phase: LOADING,
        };
      case GET_TRANSLATON_DATA_SUCCESS:
        return {
          ...state,
          phase: SUCCESS,
          data: action.data,
          error: null,
        };
      case GET_SOFTWARE_TYPE_SUCCESS: 
        return {
          ...state,
          phase: SUCCESS,
          software: action.data,
          error: null
        }  
      case GET_TRANSLATON_DATA_ERROR: case GET_SOFTWARE_TYPE_ERROR:
        return { ...state, phase: ERROR, error: action.error };
      default:
        return state;
    }
  }
  
  /**
   * Get User Search Serial Number by deatils
   * @param {String} email
   */
  
  export const getTranslation = payload => ({
    type: GET_TRANSLATON_DATA,
    payload,
  });

/**
   * Get Software Type
   * @param {String} email
   */
  
  export const getSotwareType = payload => ({
    type: GET_SOFTWARE_TYPE,
    payload,
  });
