import axios from 'axios';
import { API_URL , METHOD } from '../../utils/constants';

/**
 * Make an Api call for authorization of the user
 * @param {*} credentials 
 */
export const validateSiteCodeApi = siteCode => 
    axios({
        method: METHOD.POST,
        url: `${API_URL}/site_code_validation/`,
        headers: { 'Content-Type': 'application/json' },
        data: JSON.stringify(siteCode),
    });

/**
 * Make an Api call for authorization of the user
 * @param {*} credentials 
 */
export const generateSiteCodeApi = payload => 
    axios({
        method: METHOD.POST,
        url: `${API_URL}/generate_site_key_data/`,
        headers: { 'Content-Type': 'application/json'},
        data: JSON.stringify(payload),
    });    