import React, { useState } from 'react';
import DjangoCSRFToken from 'django-react-csrftoken'
import logo from '../../images/emerson-logo.png';


import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  Button,
  NavItem,
  Input,
  NavLink
} from 'reactstrap';
/**
 * Header Component
 */
const Headers = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [formValue, setformValue] = useState("");


  const toggle = () => setIsOpen(!isOpen);
  const handleChange = (e) => {
    setformValue(e.target.value)
    props.languageChange(e.target.value)
  }

  const logIn =(url)=>{
  window.location.href = url;
  }


  return (
    <div>
      <Navbar color="light" light expand="md">
        <NavbarBrand href="/"> <div className="emerson-logo"><img src={logo} alt="Emerson" className="logo" /></div></NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="ml-auto" navbar>
            <NavItem className="language-item"><p>{window.result ? window.result.user_name : ""}</p></NavItem>
            <NavItem className="language-item">
              <Input
                type="select"
                name="licenseissuedreason"
                id="licenseissuedreason"
                className="form-input-text language-select"
                placeholder={"Language"}
                onChange={handleChange}
                value={formValue}
              >
                <option value="en_US">English</option>
                <option value="de_DE">Deutsch</option>
                <option value="pt_BR">Português </option>
                <option value="it_IT">Italian</option>
                <option value="fr_FR">Français</option>
                <option value="ko_KR">한국어</option>
                <option value="ja_JP">日本人</option>
                <option value="zh_CN">中文</option>
                <option value="es_419">Español</option>
                <option value="ru_RU">Pусский</option>

              </Input>
            </NavItem>
            { props.error !== true ? window.result ? <NavItem>
              <form
                  action={"/oidc/logout/"}
                  method={"POST"}
              >
                <DjangoCSRFToken/>
                <Button
                className="primary-btn btn-block"
                type={"submit"}
              >
                <NavLink className="login-link" >Logout</NavLink>
              </Button>
              </form>

            </NavItem> :
            <NavItem>



                <Button
                type="button"
                className="primary-btn btn-block "
                onClick={()=>{logIn('/flow/login')}}
              >
                <NavLink  className="login-link">Login</NavLink>
              </Button>




            </NavItem> :
                null
            }

          </Nav>
        </Collapse>
      </Navbar>
    </div>
  )
}

export default Headers;