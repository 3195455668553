import axios from 'axios';
import { API_URL, METHOD } from '../../utils/constants';

/**
 * Make an Api call for authorization of the user
 * @param {*} payload 
 */
export const transalation = payload =>{
  return axios({
    method: METHOD.POST,
    url: `${API_URL}/translation/`,
    headers: { 'Content-Type': 'application/json'},

    data: JSON.stringify(payload),
  });
}

/**
 * Make an Api call for authorization of the user
 * @param {*} payload 
 */
export const softwareTypeAPI = payload =>
  axios({
    method: METHOD.POST,
    url: `${API_URL}/software_data/`,
    headers: { 'Content-Type': 'application/json'},
    data: JSON.stringify(payload),
  });