import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  Col,
  Button,
  FormFeedback,
  Badge
} from "reactstrap";
import { Customtable } from "../../components";

/**
 * Serial Number Component is Class Based based component used to handle the second tab activity.
 */

class SerialNumber extends Component {
  /**
   * Constructor and State Initilization
   * @param {*} props
   */

  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      sizePerPage: 5,
      formValues: { searchby: "Serial Number" },
      searchtextStatus: null,
      searchLabel: "Serial Number"
    };
  }

  /**
   * Declare the Static Props Types
   */
  static propTypes = {
    searchData: PropTypes.func.isRequired
  };

  /**
   * handleTableChange is used to handle the chnage in the table
   * Pageination changes
   * page length Changes
   */

  handleTableChange = (type, { page, sizePerPage }) => {
    /**
     * Set current Page and page length
     */

    this.setState(() => ({
      page,
      sizePerPage
    }));

    const { searchData } = this.props;
    const { formValues } = this.state;

    /**
     * Initialize the paylod for the api
     */

    if (formValues.searchby === "Serial Number") {
      const body = {
        serial_number: formValues.searchtext,
        purchase_order: "",
        sales_order: "",
        page_length: sizePerPage,
        current_page: page
      };
      searchData(body);
    }
    if (formValues.searchby === "Sales Order Number") {
      const body = {
        serial_number: "",
        purchase_order: "",
        sales_order: formValues.searchtext,
        page_length: sizePerPage,
        current_page: page
      };
      searchData(body);
    }
    if (formValues.searchby === "Purchase Order Number") {
      const body = {
        serial_number: "",
        purchase_order: formValues.searchtext,
        sales_order: "",
        page_length: sizePerPage,
        current_page: page
      };
      searchData(body);
    }
  };

  /**
   * Handle the form data on changes values
   */

  handleChange = e => {
    const { formValues } = this.state;
    formValues[e.target.name] = e.target.value;
    this.setState(() => ({ formValues }));
    this.validateSerialNumber(e.target.value);
    if (e.target.name === "searchby") {
      this.setState({
        searchLabel: e.target.value
      });
    }
  };

  /**
   * validate Serial number
   */

  validateSerialNumber = searchText => {
    if (searchText) {
      this.setState({ searchtextStatus: false });
    } else {
      this.setState({ searchtextStatus: true });
    }
  };

  /**
   * validate form is used to validate the form before calling tha Api
   * It checks whether the serach text is empty or Not
   * If its Empty it generate the error and do not call APi
   * If everything is validated properly It trigger the Api and fetch the data with requestd parameters.
   */

  validateForm = () => {
    const { formValues } = this.state;

    if (formValues.searchtext) {
      this.setState({ searchtextStatus: false, sizePerPage: 5, page: 1 });
      const { searchData } = this.props;
      const { sizePerPage } = this.state;
      if (formValues.searchby === "Serial Number") {
        const body = {
          serial_number: formValues.searchtext.replace(/\s/g, ''),
          purchase_order: "",
          sales_order: "",
          page_length: sizePerPage,
          current_page: 1
        };
        searchData(body);
      }
      if (formValues.searchby === "Sales Order Number") {
        const body = {
          serial_number: "",
          purchase_order: "",
          sales_order: formValues.searchtext.replace(/\s/g, ''),
          page_length: sizePerPage,
          current_page: 1
        };
        searchData(body);
      }
      if (formValues.searchby === "Purchase Order Number") {
        const body = {
          serial_number: "",
          purchase_order: formValues.searchtext.replace(/\s/g, ''),
          sales_order: "",
          page_length: sizePerPage,
          current_page: 1
        };
        searchData(body);
      }
    } else {
      return this.setState({ searchtextStatus: true });
    }
  };

  /**
   * proLinkButton Function is used to add the button in the coloumn
   * Its check the proLink quantity if it is greater than 0 it will show proLink Activate Button and user can able to click.
   * If quantity is less the 0 Activated button will be visible means he was not able to click on button.
   */

  proLinkButton = (cell, row) => {
    if (row.Licensed_qty > 0) {
      return (
        <span>
          <Badge color="primary" className="custom-badge">
            {" "}
            Activate{" "}
          </Badge>
        </span>
      );
    }

    return (
      <span>
        <Badge color="secondary"> Activated </Badge>
      </span>
    );
  };

  /**
   * navigateToSofwareTab tab is used when user click on the activate button it will allow the user to
   * navigate to first tab with the current serial value
   */

  navigateToSofwareTab = (serialNumber, application) => {
    this.props.onTabChange("1", serialNumber, application);
  };

  /**
   * Rendering JSX Elements.
   */

  render() {
    const { sizePerPage, page, searchtextStatus, searchLabel } = this.state;
    const {
      translation: { data }
    } = this.props;
    const {
      serialNumber: { searchData }
    } = this.props;
    const columns = [
      {
        dataField: "Sales_order_number",
        text: data !== null ? data.sales_ord_num_drop_down : ""
      },
      {
        dataField: "Customer_PO",
        text:  data !== null ? data.customer_po_table_col : ""
      },
      {
        dataField: "Serial_number",
        text: data !== null ? data.serial_num_label : ""
      },
      {
        dataField: "proLinkButton",
        text: data !== null ? data.license_avail_table_col : "",
        formatter: this.proLinkButton,
        events: {
          onClick: (e, column, columnIndex, row, rowIndex) => {
            if (row.Licensed_qty > 0) {
              this.navigateToSofwareTab(
                row.Serial_number,
                row.Application_name
              );
            }
          }
        }
      },
      {
        dataField: "Licensed_qty",
        text: data !== null ? data.no_of_avail_table_col : "",
      },
      {
        dataField: "Application_name",
        text: data !== null ? data.application_name : ""
      },
      {
        dataField: "Issued_by",
        text: data !== null ? data.license_issued_by_table_col : ""
      }
    ];
    return (
      <>
        <Row className="row-tab" data-test={"serialnumber"}>
          <Col sm="12">
            <Form autoComplete="off">
              <FormGroup row>
                <Col sm={4}>
                  <Label
                    for="searchbySelect"
                    className="custom-label select-drop-label"
                  >
                    {data !== null ? data.search_by_label : "Search By"}
                  </Label>
                  <Input
                    type="select"
                    name="searchby"
                    id="searchbySelect"
                    className="form-dropdown-text"
                    placeholder={
                      data !== null ? data.search_by_label : "Search By"
                    }
                    onChange={this.handleChange}
                  >
                    <option>
                      {data !== null ? data.serial_num_label : ""}
                    </option>
                    <option>
                      {data !== null ? data.sales_ord_num_drop_down : ""}
                    </option>
                    <option>
                      {data !== null ? data.purchase_ord_num_drop_down : ""}
                    </option>
                  </Input>
                </Col>
                <Col sm={4}>
                  <Label for="searchbySelect" className="custom-label ">
                    {searchLabel + "*"}
                  </Label>
                  <Input
                    type="text"
                    name="searchtext"
                    id="serialtext"
                    className="form-input-text"
                    placeholder={searchLabel}
                    invalid={searchtextStatus}
                    onChange={this.handleChange}
                    valid={searchtextStatus === false ? true : false}
                  />
                  <FormFeedback invalid={"true"}>
                    {data !== null ? data.search_error_msg : ""}
                  </FormFeedback>
                </Col>

                <Col sm={4} className={"top-margin-input"}>
                  <Button
                    className="btn-block primary-btn"
                    type="button"
                    onClick={this.validateForm}
                  >
                    {data !== null ? data.search_btn : "Search"}
                   
                      <i className="fa fa-chevron-right hvr-icon" aria-hidden="true"></i>
                    
                  </Button>
                </Col>
              </FormGroup>
            </Form>
            <Customtable
              data={searchData !== null ? searchData.Search_results : []}
              keyField={"id"}
              page={page}
              sizePerPage={sizePerPage}
              hideSizePerPage={true}
              totalSize={searchData !== null ? searchData.Total_records : ""}
              onTableChange={this.handleTableChange}
              columns={columns}
            />
          </Col>
        </Row>
      </>
    );
  }
}
export default SerialNumber;
