
/**
 * Declare the Constants variable
 * Get authorization constants
 */
const GET_AUTH = 'GET_AUTH';
const GET_AUTH_SUCCESS = 'GET_AUTH_SUCCESS';
const GET_AUTH_ERROR = 'GET_AUTH_ERROR';

/**
 * Export the constants
 * GET_AUTH
 * GET_AUTH_ERROR
 * GET_AUTH_SUCCESS
 */

export{
    GET_AUTH,
    GET_AUTH_ERROR,
    GET_AUTH_SUCCESS
}