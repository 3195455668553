import React, { Component } from "react";
import { Row, Form, FormGroup, Label, Input, Col } from "reactstrap";
import SiteKeyReport from "./SiteKeyReport";
import GoodWillReport from "./GoodWillReport";

/**
 * Reports Class based components used to handel the Reports Function
 */

class Reports extends Component {
  /**
   * Construtor and Sate initilization
   * @param {*} props
   */

  constructor(props) {
    super(props);

    /**
     * Initial State
     */

    this.state = {
      match: "All",
      reportType: "goodwill"
    };
  }

  /**
   * Select Type Method used for the All and Any
   * Which we can send to the child component for Furthur validation process
   * If All is selected then all the form values are required
   * If Any is select then any one fied is required
   */

  selectType = e => {
    this.setState({
      match: e.target.value
    });
  };

  /**
   * Report Type Method used for the goodwill and sitekey
   * Which we can send to the child component for Furthur validation process
   * If goodwill is selected then goodwill api call
   * If site is select then site key api call
   */
  reportType = e => {
    this.setState({
      reportType: e.target.value
    });
  };

  /**
   * Render the JSX Elements.
   */

  render() {
    const { match, reportType } = this.state;
    const {
      translation: { data }
    } = this.props;
    return (
      <>
        <Row className="row-tab" data-test={'report'}>
          <Col sm="12">
            <h2>
              {data !== null ? data.search_license_heading : "Search License"}{" "}
            </h2>
            <Form className="row-tab">
              <FormGroup row>
                <Label for="exampleEmail" sm={2} className="radio-button-label">
                  Match
                </Label>
                <Col sm={2}>
                  <Label className="radio-button">
                    {data !== null ? data.all_check_box : "All"}
                    <Input
                      type="radio"
                      name="match"
                      className="form-radio"
                      defaultChecked
                      value={"All"}
                      onChange={this.selectType}
                    />{" "}
                    <span className="checkmark"></span>
                  </Label>
                </Col>
                <Col sm={2}>
                  <Label className="radio-button">
                    {data !== null ? data.any_check_box : "Any"}
                    <Input
                      type="radio"
                      name="match"
                      className="form-radio"
                      value={"Any"}
                      onChange={this.selectType}
                    />{" "}
                    <span className="checkmark"></span>
                  </Label>
                </Col>
                <Col sm={{ size: 3, offset: 3 }}>
                  <Input
                    type="select"
                    name="reporttype"
                    id="exampleSelect"
                    className="form-input-text"
                    placeholder="Email Address"
                    onChange={this.reportType}
                    value={reportType}
                  >
                    <option value={"goodwill"}>
                      {data !== null
                        ? data.goodwill_report_drop_down
                        : "Goodwill Report"}
                    </option>
                    <option value={"sitekey"}>
                      {data !== null
                        ? data.site_key_report_drop_down
                        : "Site Key Report"}
                    </option>
                  </Input>
                </Col>
              </FormGroup>
            </Form>
            {reportType === "goodwill" ? (
              <GoodWillReport selectedtype={match} />
            ) : (
              <SiteKeyReport selectedtype={match} />
            )}
          </Col>
        </Row>
      </>
    );
  }
}

/**
 * Export the Report Class Container
 * MapstateToprops is used to connect the state value to the container
 * MapdispatchToProps is used to dispatch the class fuction to the redux
 */

export default Reports;
