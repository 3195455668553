import React, { Component } from "react";
import {
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Col,
  Button,
  FormFeedback,
  Modal,
  ModalHeader,
  ModalBody,
  Table
} from "reactstrap";
import PropTypes from "prop-types";
import { debounce } from "debounce";
import { Instruction } from "../../components";
import imageServlet from "../../images/imageServlet.jpeg";
import {
  RestrictionType,
  RestrictionCount,
  NumberOfCopies,
  Options,
  Levels
} from "../../utils/crypkeyConstants";
import {
  validateEmail,
  insertSpace,
  isHexadecimal,
  siteCodeError
} from "../../utils/methods"
/**
 * Software Activation component is calss based component used to handel the first tab events.
 */

class SoftwareActivation extends Component {
  /**
   * Constructor and State Initilization
   * @param {*} props
   */

  constructor(props) {
    super(props);
    this.state = {
      tooltipOpen: false,
      formValues: {},
      loading: false,
      error: {},
      emailStatus: null,
      serialNumberStatus: null,
      selectappStatus: null,
      sitecodeStatus: null,
      sitecodevalue: null,
      mainModal: false,
      secondaryModal: false,
      sitecode: true,
      errorModal: false,
      errorTextSiteCode1: '',
      errorTextSiteCode2: '',
    };
  }

  /**
   * Declare Static Proptype Types of the varaiables
   */

  static propTypes = {
    validateSerialKeySoftware: PropTypes.func.isRequired,
    validateSiteCode: PropTypes.func.isRequired,
    generateSiteKey: PropTypes.func.isRequired
  };

  /**
   * handleChange function is used to handle the click event and trigger the second tab active on click.
   */

  handleLangChange = () => {

    this.props.onTabChange("2");
  };

  /**
   * Handle the form data on changes values
   */
  handleChange = e => {
    const { formValues } = this.state;
    
    formValues[e.target.name] = e.target.value;
    this.setState(() => ({ formValues }));
    if (e.target.name === "email") {
      this.setState({emailStatus: !validateEmail(e.target.value)})
    }
    if (e.target.name === "serialnumber") {
      this.validateSerialNumber(e.target.value);
      formValues["selectapp"] = "";
      this.setState(() => ({ formValues, selectappStatus: null }));
      this.props.setSerialKey();
    }
    if (e.target.name === "selectapp") {
      this.validateSoftwareType(e.target.value);
    }
    if (e.target.name === "sitecodevalue") {
      this.validateSite(e.target.value);
    }
  };

  /**
   * Clear the form fileds and reset to initial state
   */
  clearForm = () => {
    this.setState({
      tooltipOpen: false,
      formValues: {
        email: "",
        serialnumber: "",
        selectapp: "",
        sitecodevalue: ""
      },
      loading: false,
      error: {},
      emailStatus: null,
      serialNumberStatus: null,
      selectappStatus: null,
      sitecodeStatus: null,
      sitecodevalue: null,
      mainModal: false,
      secondaryModal: false,
      sitecode: true,
      errorMsg: []
    });
  };

  validateSite = debounce(siteCode => {
    const {validateSiteCode} = this.props;
    const {formValues} = this.state;
    let siteCodeArray = [];

    [...siteCode].forEach(c => {
      let letter = c.replace(/\s/g, '');
      letter = letter ? letter.toUpperCase() : ''
      letter = isHexadecimal(letter) ? letter : '';
      siteCodeArray.push(letter);
    })

    siteCode = siteCodeArray.join('').slice(0, 18);
    siteCode = insertSpace(siteCode, 4);

    if (siteCode.length === 22) {
      formValues.sitecodevalue = siteCode;
      this.setState({
        sitecodeStatus: false,
        formValues,
        errorTextSiteCode1: siteCodeError('invalid'),
        errorTextSiteCode2: siteCodeError('contactAssistance')
      });
      validateSiteCode({site_code: siteCode});
    } else {
      formValues.sitecodevalue = siteCode;
      formValues.selectapp = "";
      this.setState({sitecodeStatus: false, formValues});
    }
  }, 700);

  validateSiteOnBlur = e => {
    const {formValues} = this.state;
    if (e.target.value && e.target.value.length !== 22) {
      formValues.sitecodevalue = e.target.value;
      formValues.selectapp = "";
      this.setState({sitecodeStatus: true,
        formValues,
        errorTextSiteCode1: siteCodeError('tooFewChars'),
        errorTextSiteCode2: ''
      });
    }
  }

  validateSoftwareType = type => {
    const { translation: { data } } = this.props;
    if (type) {
      this.setState({ selectappStatus: false });
      const {
        userStore: { serialsoft }
      } = this.props;
      const selected = serialsoft !== null ? serialsoft.validate_data.find(
        selected => selected.software_type === type
      ): "";
      
      if (selected) {
        selected.license_qty > 0
        ? this.setState({ sitecode: false, errorMsg: [] })
        : this.setState({
            errorModal: true,
            errorMsg: selected ? selected.message[0] : ""
          });
      }else{
        const serialType = serialsoft ? serialsoft.validate_data[0].software_type : " ";
        const ErrorMessage = data ? `${data.error_1} ${type} ${data.error_2} ${serialType}${data.error_3}`: "";
        this.setState({
          errorModal: true,
          errorMsg: ErrorMessage,
        })
      }
      
     
    } else {
      this.setState({ selectappStatus: true });
    }
  };

  /**
   * validate Serial number
   */
  validateSerialNumber = debounce(serialNumber => {
    if (serialNumber) {
      const { validateSerialKeySoftware } = this.props;
      const body = {
        serial_number: serialNumber.replace(/\s/g, '')
      };
      validateSerialKeySoftware(body);
      this.setState({ serialNumberStatus: false });
    } else {
      this.setState({ serialNumberStatus: true });
    }
  }, 2000);

  /**
   * On click submit button Validate the form values.
   */

  validateForm = () => {
    const {
      emailStatus,
      serialNumberStatus,
      selectappStatus,
      sitecodeStatus
    } = this.state;
    if (emailStatus === null || emailStatus === true) {
      this.setState({ emailStatus: true });
    } else if (serialNumberStatus === null || serialNumberStatus === true) {
      this.setState({ serialNumberStatus: true });
    } else if (selectappStatus === null || selectappStatus === true) {
      this.setState({ selectappStatus: true });
    } else if (sitecodeStatus === null || sitecodeStatus === true) {
      this.setState({ sitecodeStatus: true });
    } else {
      this.generateCustomerLicence();
    }
  };

  /**
   * handle the main Toggle
   */

  secondaryModalToggle = () => {
    this.setState(prevState => ({
      secondaryModal: !prevState.secondaryModal
    }));
    this.props.setSerialKey();
  };

  errorModal = () => {
    this.setState(prevState => ({
      errorModal: !prevState.errorModal
    }));
  };

  generateCustomerLicence = () => {
    const { formValues } = this.state;
    const { generateSiteKey, crypKey : {siteCode} , userStore: { serialsoft },translation: { data }} = this.props;
    const body = {
      serial_number: formValues.serialnumber,
      email_id: formValues.email ,
      site_code: formValues.sitecodevalue,
      software_type: formValues.selectapp,
      options: Options.STANDARD_OPTION,
      level: Levels.STANDARD_OPTION,
      numberOfCopies: NumberOfCopies.STANDARD_OPTION,
      restrictionType: RestrictionType.None,
      restrictionCount: RestrictionCount.STANDARD_OPTION,
      reason: "",
      comments: "",
      customer_name: "",
      user_name: window.result? window.result.user_name : ""
    };
    const selected = serialsoft !== null ? serialsoft.validate_data.find(
        selected => selected.software_type === siteCode.software_type
      ): "";
      if (selected) {
      selected.license_qty > 0
        ? generateSiteKey(body)
        : this.setState({
            errorModal: true,
            errorMsg: selected ? selected.message[0] : ""
          });
          
      } else {
        const siteCodeType = siteCode ? siteCode.software_type : " ";
        const serialType = serialsoft ? serialsoft.validate_data[0].software_type : " ";
        const ErrorMessage = data ? `${data.error_1} ${siteCodeType} ${data.error_2} ${serialType}${data.error_3}`: "";
        this.setState({
          errorModal: true,
          errorMsg: ErrorMessage,
        })
      }   
  };

  /**
   * Rendering the JSX Elements.
   */
  checkSiteCode = response => {
    const {  formValues } = this.state;
    const appName = response ? response.software_type : "";
    if (appName){
      formValues["selectapp"] = response ? response.software_type : "";
      this.setState({ formValues})
      this.validateSoftwareType(appName)
    }
  } 

  componentDidMount() {
   const  formValues = {
        email: window.result ? window.result.mail_id : ""
      }

   this.setState({formValues})
   if(window.result) {
     this.setState({emailStatus: !validateEmail(window.result.mail_id)})
   }
  }


  componentDidUpdate(prevProps,prevState) {
    const {
      userStore: { email },
      authStore: { auth }
    } = this.props;
    const {
      userStore: { serialsoft },
      serialKey,
      crypKey: { siteCode, siteKey }
    } = this.props;
    const { formValues } = this.state;
    if (prevProps.userStore.email !== email) {
      if (email.status === 200) {
        this.setState({emailStatus: false})
      } else {
        this.setState({emailStatus: true});
      }
    }
    if (prevProps.crypKey.siteCode !== siteCode) {
      siteCode.status === 200
        ? this.checkSiteCode(siteCode)
        : this.setState({ sitecodeStatus: true , formValues:{selectapp : ""}});
    }
    if (prevProps.crypKey.siteKey !== siteKey) {
      if (siteKey.status === 200) {
        formValues.serialnumber = "";
        formValues.selectapp = "";
        formValues.sitecodevalue = "";
        this.setState({
          secondaryModal: true,
          formValues: formValues,
          serialNumberStatus: null,
          selectappStatus: null,
          sitecodeStatus: null,
          sitecode: true
        });
      } else {
        this.setState({ secondaryModal: false });
      }
    }
     if (prevProps.userStore.serialsoft !== serialsoft) {
      serialsoft.status === 200
        ? this.setState({ serialNumberStatus: false })
        : this.setState({ serialNumberStatus: true });
      if (serialsoft.validate_data.length > 0) {
          this.setState({ sitecode: false });
      } else {
        this.setState({ sitecode: true });
      }

        /*check for any change in serial and update Software Type*/

      if(siteCode !== null && siteCode !== undefined && formValues.sitecodevalue !== "" && formValues.sitecodevalue !== null &&
         formValues.sitecodevalue !== undefined
      )
      {
          if (serialsoft.validate_data.length > 0 && serialsoft !== null) {
                siteCode.status === 200
        ? this.checkSiteCode(siteCode)
        : this.setState({ sitecodeStatus: true });
            }


      }
    }

    if (prevProps.serialKey !== serialKey ) {
      const { formValues } = this.state;
      formValues["serialnumber"] = serialKey;
      formValues["sitecodevalue"] = "";
      formValues["selectapp"] = "";
      this.setState(() => ({ formValues, selectappStatus: false }));
      this.validateSerialNumber(serialKey);

    }

    if (prevProps.authStore.auth !== auth) {
      const { formValues } = this.state;
      formValues["email"] = auth.mail_id;
      this.setState({ formValues, emailStatus: false });
    }

  }

  render() {
    const {
      emailStatus,
      serialNumberStatus,
      formValues,
      sitecode,
      selectappStatus,
      sitecodeStatus,
      errorMsg,
      errorTextSiteCode1,
      errorTextSiteCode2,
    } = this.state;
    const {
      translation: { data },
      crypKey: { siteKey }
    } = this.props;

    return (
      <>
        <Row className="row-tab" data-test={'softwareactivation'}>
          <Col sm="12">
            <Form autoComplete="off">
              <FormGroup row>
                <Col sm={4}>
                  <Label for="exampleEmail" className="custom-label">
                    {data !== null
                      ? data.email_addr_label + "*"
                      : "Email Address"}
                  </Label>
                  <Input
                    type="email"
                    name="email"
                    id="exampleEmail"
                    className={"form-input-text"}
                    placeholder={
                      data !== null ? data.email_addr_label : "Email Address"
                    }
                    value={formValues.email}
                    defaultValue={window.result ? window.result.mail_id : null}
                    onChange={this.handleChange}
                    invalid={emailStatus}
                    valid={emailStatus === false}
                  />
                  <FormFeedback invalid={"true"}>
                    {data !== null
                      ? data.email_error_msg
                      : "Invalid Email Address"}
                  </FormFeedback>
                  <FormText className="custom-text">
                    {data !== null ? data.email_addr_info : ""}
                  </FormText>
                </Col>
                <Col sm={4}>
                  <Label for="exampleEmail" className="custom-label">
                    {data !== null
                      ? data.serial_num_label + "*"
                      : "Serial Number"}
                  </Label>
                  <Input
                    type="text"
                    name="serialnumber"
                    id="serialNumber"
                    className="form-input-text"
                    placeholder={
                      data !== null ? data.serial_num_label : "Serial Number"
                    }
                    onChange={this.handleChange}
                    invalid={serialNumberStatus}
                    valid={serialNumberStatus === false}
                    value={formValues.serialnumber}
                  />
                  <FormFeedback invalid={"true"}>
                    {data !== null
                      ? data.serial_num_error_msg
                      : "Serial Number"}
                  </FormFeedback>
                  <FormText className="custom-text">
                    {data !== null ? data.serial_num_info : ""}
                  </FormText>
                </Col>
                <Col sm={4}>
                  <p
                    className="search-for"
                    onClick={() => {
                      this.handleLangChange();
                    }}
                  >
                    <i className="fa fa-search" aria-hidden="true"></i>{" "}
                    {data !== null ? data.search_serial_num_link : ""}
                  </p>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col sm={4}>
                  <Label
                    for="exampleEmail"
                    className="custom-label "
                  >
                    {data !== null
                      ? data.software_type + "*"
                      : "Software Type*"}
                  </Label>
                  <Input
                    type="text"
                    name="selectapp"
                    id="selectapp"
                    className="form-input-text"
                    onChange={this.handleChange}
                    placeholder={
                      data !== null ? data.software_type : "Software Type"
                    }
                    invalid={selectappStatus}
                    valid={selectappStatus === false}
                    value={formValues.selectapp}
                    disabled={true}
                  >
                   
                  </Input>
                  <FormFeedback invalid={"true"}>
                    {data !== null ? data.select_software_type : ""}
                  </FormFeedback>
                  <FormText className="custom-text">
                  {data !== null ? data.software_type_msg : ""} 
                  </FormText>
                </Col>
                <Col sm={4}>
                  <Label for="exampleEmail" className="custom-label">
                    {data !== null ? data.site_code_label + "*" : "Site Code"}
                  </Label>
                  <Input
                    type="text"
                    name="sitecodevalue"
                    id="siteCode"
                    className="form-input-text"
                    placeholder={
                      data !== null ? data.site_code_label : "Site Code"
                    }
                    onChange={this.handleChange}
                    invalid={sitecodeStatus}
                    valid={sitecodeStatus === false}
                    disabled={sitecode}
                    value={formValues.sitecodevalue}
                    onBlur={this.validateSiteOnBlur}
                  />
                  <FormFeedback invalid={"true"}>
                    {errorTextSiteCode1}<br/>
                    {errorTextSiteCode2}
                  </FormFeedback>
                  <FormText className="custom-text">
                    {data !== null ? data.site_code_info : ""}
                  </FormText>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col sm={{ size: 4 }} className={"top-margin-input"}>
                  <Button
                    className="btn-block primary-btn"
                    type="button"
                    onClick={this.validateForm}
                  >
                    {data !== null
                      ? data.generate_site_key_btn
                      : "Generate Site Key"}{" "}
                    <i className="fa fa-chevron-right hvr-icon" aria-hidden="true"></i>
                    
                  </Button>
                </Col>
                <Col sm={{ size: 4 }} className={"top-margin-input"}>
                  <Button
                    className="btn-block secondary-btn"
                    type="button"
                    onClick={this.clearForm}
                  >
                    {data !== null ? data.clear_btn + " "  : "Clear "}
                   
                      <i className="fa fa-chevron-right hvr-icon" aria-hidden="true"></i>
                   
                  </Button>
                </Col>
                <Modal
                  isOpen={this.state.secondaryModal}
                  className={this.props.className}
                  centered={true}
                >
                  <ModalHeader toggle={this.secondaryModalToggle} >
                    {data !== null ? data.site_key_success_msg : ""}
                  </ModalHeader>
                  <ModalBody>
                    <Row>
                      <Col sm={"12"}>
                        <p>
                          {data !== null
                            ? data.site_key_gen_sent_email_msg
                            : ""}
                        </p>
                        <Table borderless className="softwareActivation">
                          <tbody className={"display-table"}>
                            <tr>
                              <td className={"tdhead"}>
                                {data !== null
                                  ? data.email_addr_label
                                  : "Email Address"}
                              </td>
                              <td>{siteKey !== null ? siteKey.email : ""}</td>
                            </tr>
                            <tr>
                              <td className={"tdhead"}>
                                {data !== null
                                  ? data.site_code_label
                                  : "Site Code"}
                              </td>
                              <td>
                                {siteKey !== null ? siteKey.site_code : ""}
                              </td>
                            </tr>
                            <tr>
                              <td className={"tdhead"}>
                                {data !== null
                                  ? data.site_key_label
                                  : "Site Key"}{" "}
                              </td>
                              <td>
                                {siteKey !== null ? siteKey.site_key : ""}
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </Col>
                    </Row>
                  </ModalBody>
                </Modal>
                <Modal
                  isOpen={this.state.errorModal}
                  centered={true}
                  className={this.props.className}
                >
                  <ModalHeader toggle={this.errorModal} >
                    {data !== null ? data.pop_up_header_1 : "ERROR"}
                  </ModalHeader>
                  <ModalBody >{errorMsg}</ModalBody>
                </Modal>
              </FormGroup>
            </Form>
          </Col>
          <Col sm="6" className="instruction">
            <Instruction transData={data !== null ? data : null} />
          </Col>
          <Col sm="6" className="img-padding">
            <img src={imageServlet} alt="imageServlet" className="img-fluid" />
          </Col>
        </Row>
      </>
    );
  }
}
export default SoftwareActivation;
