/**
 * initilized the intial constants variable for Translation.
 */

const GET_USER = 'GET_USER';
const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
const GET_USER_ERROR = 'GET_USER_ERROR';
const LOGOUT_USER = 'LOGOUT_USER';
const UNAUTH_USER = 'UNAUTH_USER';

const VALIDATE_SERIAL = 'VALIDATE_SERIAL';
const VALIDATE_SERIAL_SOFTWARE = 'VALIDATE_SERIAL_SOFTWARE';
const VALIDATE_SERIAL_SOFTWARE_SUCCESS = 'VALIDATE_SERIAL_SOFTWARE_SUCCESS';
const VALIDATE_SERIAL_SUCCESS = 'VALIDATE_SERIAL_SUCCESS';
const VALIDATE_SERIAL_ERROR = 'VALIDATE_SERIAL_ERROR';
const VALIDATE_SITE_CODE = 'VALIDATE_SITE_CODEL';
const VALIDATE_SITE_CODE_SUCCESS = 'VALIDATE_SITE_CODE_SUCCESS';
const VALIDATE_SITE_CODE_ERROR = 'VALIDATE_SITE_CODE_ERROR';

/**
 * Export the initial varibale for Store and Reducers
 * Validate Email Address
 * Validate Site Code
 * Get user access
 */

export {
  GET_USER, GET_USER_SUCCESS, GET_USER_ERROR, LOGOUT_USER, UNAUTH_USER,VALIDATE_SERIAL_SOFTWARE,VALIDATE_SERIAL_SOFTWARE_SUCCESS,
  VALIDATE_SERIAL,VALIDATE_SERIAL_SUCCESS,VALIDATE_SERIAL_ERROR,VALIDATE_SITE_CODE,VALIDATE_SITE_CODE_SUCCESS,VALIDATE_SITE_CODE_ERROR
};