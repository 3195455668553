import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import {userStore} from "./user";
import { serialNumber } from "./serialnumber";
import { translation } from "./translation";
import { crypKeyStore } from "./crypkey";
import { authStore } from './auth';
import { reportStore } from './report';
import { ROOT } from './../utils/constants';

/**
 * Configure The Persistor
 */

const persistConfig = {
  key: ROOT,
  storage
};

/**
 * Combine All Reducers into One
 * userstore
 * authstore
 * serialnumber
 * translation
 * crypkey
 * report store
 */
const rootReducer = combineReducers({
  authStore: persistReducer(persistConfig, authStore),
  userStore:  userStore,
  serialNumber: serialNumber,
  translation :  translation,
  crypKey : crypKeyStore,
  reportStore: reportStore
});

/**
 * Export all the store
 */

export default rootReducer;