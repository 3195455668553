import { takeLatest, call, put } from 'redux-saga/effects';
import {
    GET_TRANSLATON_DATA, GET_TRANSLATON_DATA_SUCCESS, GET_TRANSLATON_DATA_ERROR,
    GET_SOFTWARE_TYPE, GET_SOFTWARE_TYPE_ERROR, GET_SOFTWARE_TYPE_SUCCESS
  } from './constants';
import { transalation, softwareTypeAPI } from './api';

/**
 * action Type of GET_USER
 * @param {Object} action
 */
function* translationData(action) {
  try {
    const {aol_dictionary} = yield call(transalation, action.payload);
    const data = aol_dictionary;
    yield put({ type: GET_TRANSLATON_DATA_SUCCESS, data});
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_TRANSLATON_DATA_ERROR, error });
  }
}

/**
 * Get User Saga
 */
export function* getTranslationSaga() {
  yield takeLatest(GET_TRANSLATON_DATA, translationData);
}

/**
 * action Type of GET_USER
 * @param {Object} action
 */
function* softwareType(action) {
  try {
    const {software_data} = yield call(softwareTypeAPI, action.payload);
    const data = software_data;
    yield put({ type: GET_SOFTWARE_TYPE_SUCCESS, data});
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_SOFTWARE_TYPE_ERROR, error });
  }
}

/**
 * Get User Saga
 */
export function* softwareTypeSaga() {
  yield takeLatest(GET_SOFTWARE_TYPE, softwareType);
}