/**
 * initilized the intial constants variable for Translation.
 */

const GET_TRANSLATON_DATA = 'GET_TRANSLATON_DATA';
const GET_TRANSLATON_DATA_SUCCESS = 'GET_TRANSLATON_DATA_SUCCESS';
const GET_TRANSLATON_DATA_ERROR = 'GET_TRANSLATON_DATA_ERROR';

const GET_SOFTWARE_TYPE = "GET_SOFTWARE_TYPE";
const GET_SOFTWARE_TYPE_SUCCESS = "GET_SOFTWARE_TYPE_SUCCESS";
const GET_SOFTWARE_TYPE_ERROR = "GET_SOFTWARE_TYPE_ERROR";

/**
 * Export the Constants
 * It is used in the translation store as action type
 */

export {
    GET_TRANSLATON_DATA, GET_TRANSLATON_DATA_SUCCESS, GET_TRANSLATON_DATA_ERROR,GET_SOFTWARE_TYPE,GET_SOFTWARE_TYPE_ERROR,GET_SOFTWARE_TYPE_SUCCESS
};