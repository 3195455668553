import { connect } from "react-redux";
import Emerson from "./Emerson";
import { validateSiteCodeEmerson, generateSiteKey } from "../../../store/crypkey";

/**
 * Connect the Store State to the components
 * @param {*} state
 */
const mapStateToProps = state => state;

/**
 *  Dispatch the function for Api Calls
 */

const mapDispatchToProps = {
  validateSiteCodeEmerson: payload => validateSiteCodeEmerson(payload),
  generateSiteKey: payload => generateSiteKey(payload)
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Emerson);
