import React, { Component } from "react";
import {
  FormGroup,
  Label,
  Input,
  Col,
  Button,
  Row,
  Form,
  FormFeedback,
  Modal,
  ModalBody,
  ModalHeader,
  Table
} from "reactstrap";
import DatePicker from "react-datepicker2";
import { connect } from "react-redux";
import { getGoodWillReport, updateQuantityNumber , clearGoodWillReport} from "../../store/report";
import PropTypes from "prop-types";
import filterFactory, {
  textFilter,
  numberFilter,
  dateFilter,
  Comparator  
} from "react-bootstrap-table2-filter";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import moment from "moment";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

let notesFilter;
let waFilter;
let generatedDateFilter;
let quantityFilter;
let issueDateFilter;
let applicationFilter;
let generatedByFilter;
let serialNumberFilter;
let nameFilter;

class GoodWillReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formValues: {
        licensegeneratedate: "",
        licencegeneratedby: "",
        customername: "",
        issuedby: "",
        licenseissuedreason: "",
        issuedate: ""
      },
      status: {
        licencegeneratedby: null,
        customername: null,
        issuedby: null,
        issuedate: null,
        licenseissuedreason: null,
        licensegeneratedate: null
      },
      modal: false,
      licensegeneratedate: null,
      isGregorian: true,
      issuedate: null,
      row: null,
      quantity: "",
      quantityStatus: null,
      disabled: false,
    };
    this.keyCount = 0;
  }

  getKey = () => {
    return this.keyCount++;
}

  /**
   * Declare the Static-Props-Types function
   * 
   */
  static propTypes = {
    updateQuantityNumber: PropTypes.func.isRequired,
    getGoodWillReport: PropTypes.func.isRequired,
    clearGoodWillReport: PropTypes.func.isRequired
  };

  /**
   * Handle the form Submission and validation
   */

  submitForm = () => {
    const formvalid = this.validateFields();
    const { getGoodWillReport } = this.props;
    const body = this.createRequestBody();
    if (formvalid) {
      getGoodWillReport(body);
      notesFilter("");
      waFilter("")
      nameFilter("");
      generatedByFilter("");
      generatedDateFilter("");
      serialNumberFilter("");
      quantityFilter("");
      issueDateFilter("");
      applicationFilter("");
      this.setState(() => ({ disabled: false }));
    }
  };

  /**
   * Clear the form fileds and reset to initial state
   */
  clearForm = () => {
    const {clearGoodWillReport } = this.props;
    clearGoodWillReport();
    this.setState({
      formValues: {
        issuedate: "",
        issuedby: "",
        licencegeneratedby: "",
        customername: "",
        licenseissuedreason: "",
        licensegeneratedate: "",
        
      },
      status: {
        issuedby: null,
        issuedate: null,
        customername: null,
        licencegeneratedby: null,
        licenseissuedreason: null,
        licensegeneratedate: null,
      },
      modal: false,
      licensegeneratedate: null,
      issuedate: null,
      isGregorian: true,
      quantityStatus: null,
      row: null,
      quantity: "",
      disabled: true
    });
  };

  /**
   * Create Request Body
   */

  createRequestBody = () => {
    
    /**
     * Reading the formvalues from the sate
     */

    const { formValues } = this.state;
    
    const formatedDate1 = formValues.issuedate
      ? moment(formValues.issuedate).format("DD-MMM-YY")
      : "";
    const formatedDate = formValues.licensegeneratedate
      ? moment(formValues.licensegeneratedate).format("DD-MMM-YY")
      : "";

    const body = {
      license_generated_date: formatedDate,
      customer_name: formValues.customername.trim(),
      license_generated_by: formValues.licencegeneratedby.trim(),
      issued_date: formatedDate1,
      issue_reason: formValues.licenseissuedreason,
      issued_by: formValues.issuedby.trim(),
      match: this.props.selectedtype
    };
    return body;
  };

  /**
   * Validate the form if Selected Type is All
   */

  validateFields = () => {
    const { formValues } = this.state;
    let formvalid = false;
    let validStatus = {},
      count = 0;

    Object.keys(formValues).map(function(key, index) {
      if (formValues[key]) {
        count += 1;
      }
      return count;
    });
    if (count > 0) {
      validStatus = {
        licencegeneratedby: false,
        customername: false,
        issuedby: false,
        licenseissuedreason: false,
        licensegeneratedate: false,
        issuedate: false
      };
      formvalid = true;
    } else {
      validStatus = {
        licencegeneratedby: true,
        customername: true,
        issuedby: true,
        licenseissuedreason: true,
        issuedate: true,
        licensegeneratedate: true
      };
      toast.error("At least one field should be filled", {
        position: "top-center",
        autoClose: 5000,
        closeOnClick: true,
        hideProgressBar: false,
        draggable: true,
        pauseOnHover: true,
      });
    }
    this.setState({ status: validStatus });
    return formvalid;
  };

  /**
   * Handle Change Function
   */

  handleChange = e => {
    const { formValues } = this.state;
    formValues[e.target.name] = e.target.value;
    this.setState(() => ({ formValues, disabled: true }));
    this.validateFields();
  };
  componentDidUpdate(prevProps, prevState) {
    const { getGoodWillReport, reportStore: { updateQuantity }} = this.props;
    const { licensegeneratedate, issuedate, formValues } = this.state;
    if (prevState.issuedate !== issuedate) {
      formValues.issuedate = issuedate
        ? issuedate.locale("en").format("MM/DD/YYYY")
        : "";
      this.setState({ formValues });
      this.validateFields();
    }
    if (prevState.licensegeneratedate !== licensegeneratedate) {
      formValues.licensegeneratedate = licensegeneratedate
        ? licensegeneratedate.locale("en").format("MM/DD/YYYY")
        : "";
      this.setState({ formValues });
      this.validateFields();
    }
    if (prevProps.reportStore.updateQuantity !== updateQuantity) {
      const body = this.createRequestBody();
      getGoodWillReport(body);
    }
  }
  EditFiled = (cell, row) => {
    return (
      <span className="edit">
        <i className="fa fa-pencil-square-o fa-lg"></i>
      </span>
    );
  };
  editPopUp = row => {
    this.setState(prevState => ({
      row: row,
      quantity: row.LICENSED_QTY,
      modal: true
    }));
  };

  updateQuantity = e => {
    const number = e.target.value;
    if (/^([1-9][0-9]{0,2}|1000)$/.test(number)) {
      this.setState({ quantityStatus: false, quantity: number });
    } else {
      this.setState({ quantityStatus: true, quantity: number });
    }
  };

  closePopUp = () => {
    this.setState(prevState => ({
      modal: false,
      quantityStatus: false
    }));
  };

  submitPopUp = () => {
    const { quantity, row } = this.state;
    const { updateQuantityNumber } = this.props;
    if (/^(?:[1-9][0-9]{3}|[1-9][0-9]{2}|[1-9][0-9]|[1-9])$/.test(quantity)) {
      this.setState({ quantityStatus: false, modal: false });
      const body = {
        serial_number: row.SERIAL_NUMBER,
        license_quantity: quantity,
        app_name: row.APPLICATION_NAME
      };
      updateQuantityNumber(body);
    } else {
      this.setState({ quantityStatus: true });
    }
  };

  render() {
    const {
      licensegeneratedate,
      issuedate,
      status,
      formValues,
      row,
      quantityStatus,
      quantity,
      disabled
    } = this.state;
    const {
      reportStore: { goodwillreport },
      serialNumber: { reason },
      translation: { data }
    } = this.props;

    const options = {
      paginationSize: 5,
      hideSizePerPage: true, // Hide the sizePerPage dropdown always
      hidePageListOnlyOnePage: true // Hide the pagination list when only one page
    };

    const MyExportCSV = props => {
      const handleClick = () => {
        props.onExport();
      };
      return goodwillreport ? (
        <Col sm={3} className="pull-right export-csv">
          <Button
            className="btn-block primary-btn"
            type="button"
            onClick={handleClick}
            id={"export-csv"}
            disabled={disabled}
          >
            {data !== null ? data.export_to_csv+ " " : "Export to CSV "}
            <i className="fa fa-chevron-right hvr-icon" aria-hidden="true"></i>
            
          </Button>
        </Col>
      ) : (
        ""
      );
    };

    const columns = [
      {
        dataField: "CUSTOMER_NAME",
        text: data !== null ? data.customer_name_label : "",
        filter: textFilter({
          getFilter: filter => {
            nameFilter = filter;
          }
        })
      },
      {
        dataField: "EditField",
        text: data !== null ? data.edit_column : "",
        formatter: this.EditFiled,
        events: {
          onClick: (e, column, columnIndex, row, rowIndex) => {
            this.editPopUp(row);
          }
        },
        csvExport: false
      },

      {
        dataField: "SERIAL_NUMBER",
        text:  data !== null ? data.serial_num_label : "",
        filter: textFilter({
          getFilter: filter => {
            serialNumberFilter = filter;
          }
        })
      },
      {
        dataField: "APPLICATION_NAME",
        text:  data !== null ? data.application_name : "",
        filter: textFilter({
          getFilter: filter => {
            applicationFilter = filter;
          }
        })
      },
      {
        dataField: "LICENSED_QTY",
        text: data !== null ? data.no_of_avail_table_col : "",
        filter: numberFilter({
          getFilter: filter => {
            quantityFilter = filter;
          }
        }),
        headerStyle: () => {
          return { width: "140px" };
        }
      },
      
      {
        dataField: "LICENSE_GEN_DATE",
        text:  data !== null ? data.license_gen_date_label : "",
        filter: dateFilter({
          getFilter: filter => {
            generatedDateFilter = filter;
          },
          comparators: [Comparator.GT, Comparator.LT],
        }),
        headerStyle: () => {
          return { width: "200px" };
        }
      },
      {
        dataField: "LICENSE_GENERATED_BY",
        text: data !== null ? data.license_gen_by_label : "",
        filter: textFilter({
          getFilter: filter => {
            generatedByFilter = filter;
          }
        })
      },
      {
        dataField: "ISSUED_DATE",
        text: data !== null ? data.issued_date_label : "",
        filter: dateFilter({
          getFilter: filter => {
            issueDateFilter = filter;
          },
          comparators: [Comparator.GT, Comparator.LT],
        }),
        headerStyle: () => {
          return { width: "200px" };
        }
      },
      {
        dataField: "NOTES",
        text: data !== null ? data.notes_column : "",
        filter: textFilter({
          getFilter: filter => {
            notesFilter = filter;
          }
        })
      },
      {
        dataField: "WORLD_AREA",
        text: "WA",
        filter: textFilter({
          getFilter: filter => {
            waFilter = filter;
          }
        })
      },
    ];

    return (
      <>
        <Form autoComplete="off" data-test={'goodwillreport'}>
          <FormGroup row>
            <Col sm={4}>
              <Label for="licensegeneratedate" className="custom-label">
                {data !== null ? data.license_gen_date_label : "License Generated Date"}
              </Label>
              <Input
                type="text"
                name="licensegeneratedate"
                className="form-input-text"
                id="licensegeneratedate"
                placeholder={ data !== null ? data.license_gen_date_label : "License Generated Date"}
                onChange={this.handleChange}
                value={formValues.licensegeneratedate}
                invalid={status.licensegeneratedate}
                valid={status.licensegeneratedate === false ? true : false}
              />
              <Label className="calender-icon">
                <DatePicker
                  value={licensegeneratedate}
                  timePicker={false}
                  onChange={value =>
                    this.setState({ licensegeneratedate: value })
                  }
                />
                <i className="fa fa-calendar fa-lg" aria-hidden="true"></i>
              </Label>
            </Col>
            <Col sm={4}>
              <Label for="licencegeneratedby" className="custom-label">
                {data !== null ? data.license_gen_by_label : "License Generated By"}
              </Label>

              <Input
                type="text"
                name="licencegeneratedby"
                className="form-input-text"
                id="licence-generated-by"
                placeholder={ data !== null ? data.license_gen_by_label : "License Generated By" }
                value={formValues.licencegeneratedby}
                onChange={this.handleChange}
                invalid={status.licencegeneratedby}
                valid={status.licencegeneratedby === false ? true : false}
              />
            </Col>
            <Col sm={4}>
              <Label for="customername" className="custom-label">
                {data !== null ? data.customer_name_label : "Customer Name"}
              </Label>
              <Input
                type="text"
                name="customername"
                id="customername"
                className="form-input-text"
                placeholder={ data !== null ? data.customer_name_label : "Customer Name" }
                value={formValues.customername}
                onChange={this.handleChange}
                invalid={status.customername}
                valid={status.customername === false ? true : false}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col sm={4}>
              <Label for="issuedby" className="custom-label">
                {data !== null ? data.license_issued_by_table_col : "Issued By"}
              </Label>
              <Input
                type="text"
                name="issuedby"
                id="issuedby"
                className="form-input-text"
                placeholder={ data !== null ? data.license_issued_by_table_col : "Issued By" }
                value={formValues.issuedby}
                onChange={this.handleChange}
                invalid={status.issuedby}
                valid={status.issuedby === false ? true : false}
                
              />
            </Col>
            <Col sm={4}>
              <Label for="issuedate" className="custom-label">
                {data !== null ? data.issued_date_label : "Issued Date"}
              </Label>
              <Input
                type="text"
                name="issuedate"
                id="issuedate"
                className="form-input-text"
                placeholder={ data !== null ? data.issued_date_label : "Issued Date"  }
                onChange={this.handleChange}
                value={formValues.issuedate}
                invalid={status.issuedate}
                valid={status.issuedate === false ? true : false}
              />
              <Label className="calender-icon">
                <DatePicker
                  value={issuedate}
                  timePicker={false}
                  onChange={value => this.setState({ issuedate: value })}
                />
                <i className="fa fa-calendar fa-lg" aria-hidden="true"></i>
              </Label>
            </Col>

            <Col sm={4}>
              <Label for="licenseissuedreason" className="custom-label">
                {data !== null ? data.license_issued_reason_label : ""}
              </Label>
              <Input
                type="select"
                name="licenseissuedreason"
                id="licenseissuedreason"
                className="form-input-text"
                placeholder={data !== null ? data.license_issued_reason_label : ""}
                value={formValues.licenseissuedreason}
                onChange={this.handleChange}
                invalid={status.licenseissuedreason}
                valid={status.licenseissuedreason === false ? true : false}
              >
                <option value="">{data !== null ? data.select_issue_reason : ""}</option>
                {reason.map((record, i) => (
                  <option value={record} key={i}>
                    {record}
                  </option>
                ))}
              </Input>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col sm={{ size: 4 }}>
              <Button
                className="primary-btn btn-block"
                onClick={this.submitForm}
                type="button"
                id={"basic-search"}
              >
                {data !== null ? data.search_btn+ " " : "Search "}
                  <i className="fa fa-chevron-right hvr-icon" aria-hidden="true"></i>
                
              </Button>
            </Col>
            <Col sm={{ size: 4 }}>
              <Button
                className="secondary-btn btn-block"
                onClick={this.clearForm}
                type="button"
              >
                {data !== null ? data.clear_btn+ " " : "Clear "}
                <i className="fa fa-chevron-right hvr-icon" aria-hidden="true"></i>
              </Button>
            </Col>
          </FormGroup>
          <ToastContainer
            position="top-center"
            autoClose={5000}
            pauseOnHover
            newestOnTop={false}
            hideProgressBar={false}
            draggable
            closeOnClick
            rtl={false}
            pauseOnVisibilityChange
          />
        </Form>
        <Row>
          <Col sm={{ size: 12 }}>
            <ToolkitProvider
              keyField={'key'+this.getKey}
              data={goodwillreport !== null  ? goodwillreport : []}
              columns={columns}
              exportCSV={{
                exportAll: false,
                onlyExportFiltered: true,
                fileName: "Goodwill-Report.csv"
              }}
            >
              {props => (
                <div>
                  <MyExportCSV {...props.csvProps} />
                  <BootstrapTable
                    {...props.baseProps}
                    pagination={paginationFactory(options)}
                    noDataIndication="No Record Found"
                    filter={filterFactory()}
                  />
                </div>
              )}
            </ToolkitProvider>
          </Col>
        </Row>


        <Modal isOpen={this.state.modal} toggle={this.closePopUp} centered={true}>
          <ModalHeader toggle={this.closePopUp}>{data !== null ? data.pop_up_header_2: "Update License Quantity"}</ModalHeader>
          <ModalBody>
            <Table borderless className="update-table">
              <tbody>
                <tr>
                  <td className={"tdhead"}>{data !== null? data.serial_num_label + ":": "Serial Number :"}</td>
                  <td>{row !== null ? row.SERIAL_NUMBER : ""}</td>
                </tr>
                <tr>
                  <td className={"tdhead"}>{data !== null ? data.license_gen_date_label + ":" : "License Generated Date :"}</td>
                  <td>{row !== null ? row.LICENSE_GEN_DATE : ""}</td>
                </tr>
                <tr>
                  <td className={"tdhead"}>{data !== null? data.license_gen_by_label + ":" : "License Generated By :"}</td>
                  <td>{row !== null ? row.LICENSE_GENERATED_BY : ""}</td>
                </tr>
                <tr>
                  <td className={"tdhead"}>{data !== null ? data.site_code_label + ":" : "Site Code :"} </td>
                  <td>{row !== null ? row.SITE_CODE : ""}</td>
                </tr>
                <tr>
                  <td className={"tdhead"}>{data !== null ? data.issued_reason_label + ":" : "Issue Reason :"}</td>
                  <td>{row !== null ? row.ISSUE_REASON : ""}</td>
                </tr>
                <tr>
                  <td className={"tdhead"}>{data !== null ? data.license_issued_by_table_col + ":": "License Issued By :"} </td>
                  <td>{row !== null ? row.ISSUED_BY : ""}</td>
                </tr>
                <tr>
                  <td className={"tdhead"}>{data !== null? data.customer_name_label + ":" : "Customer Name :"}</td>
                  <td>{row !== null ? row.CUSTOMER_NAME : ""}</td>
                </tr>
                <tr>
                  <td className={"tdhead"}>{data !== null ? data.license_qty_label + ":" : "License Qty :"}</td>
                  <td>
                    <Input
                      type="number"
                      className="text-filter"
                      value={quantity}
                      onChange={this.updateQuantity}
                      invalid={quantityStatus}
                      valid={quantityStatus === false ? true : false}
                    />
                    <FormFeedback invalid={quantityStatus}>
                    {data !== null ? data.activation_1_to_1000 : ""}
                    </FormFeedback>
                  </td>
                </tr>
              </tbody>
            </Table>
            <Col sm={6} className="pull-right">
            <Button 
              onClick={this.submitPopUp} 
              className={"btn-block primary-btn"}
              >
            {data !== null ? data.update_btn+ " " : "Update "}
            <i className="fa fa-chevron-right  hvr-icon" aria-hidden="true"></i>
            </Button>
            </Col>
          </ModalBody>
        </Modal>
      </>
    );
  }
}


/**
 *  Dispatch the function for Api Calls
 */

const mapDispatchToProps = {
  getGoodWillReport: payload => getGoodWillReport(payload),
  updateQuantityNumber: payload => updateQuantityNumber(payload),
  clearGoodWillReport: () => clearGoodWillReport(),
};

/**
 * Connect the Store State to the components
 * @param {*} state
 */
const mapStateToProps = state => state;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GoodWillReport);
