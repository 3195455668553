import axios from 'axios';
import { API_URL , METHOD } from '../../utils/constants';

/**
 * Make an Api call for authorization of the user
 * @param {*} credentials 
 */
export const goodWillApi = payload =>
    axios({
      method: METHOD.POST,
      url: `${API_URL}/goodwill_report/`,
      headers: { 'Content-Type': 'application/json'},
      data: JSON.stringify(payload),
    });

/**
 * Make an Api call for authorization of the user
 * @param {*} credentials 
 */
export const siteKeyApi = payload =>
    axios({
      method: METHOD.POST,
      url: `${API_URL}/site_key_report/`,
      headers: { 'Content-Type': 'application/json' },
      data: payload,
    });    

/**
 * Make an Api call for authorization of the user
 * @param {*} credentials 
 */
export const updateQuantityApi = payload =>
    axios({
      method: METHOD.POST,
      url: `${API_URL}/update_serial_num_quantity/`,
      headers: { 'Content-Type': 'application/json' },
      data: payload,
    });        