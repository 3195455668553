/**
 * Common Validation Method
 * @param {*} values 
 */
const commonValidation = (values) => {
    /**
     * Declare a status variables
     */

    let status = {
        email : true,
        selectapp : true,
        name : true
    }
    
    /**
     * Check valid email
     */

    if(values.email && validateEmail(values.email)) {
       status.email = false
    }
    
    /**
     * Check application type
     */

    if(values.selectapp){
       status.selectapp = false
    }
    
    /**
     * Vlaidate customers name
     */

    if (values.customername){
       status.name = false
    }
    return status
}



const space = (str, after) =>{
  after = after || 4;
  var v = str.replace(/[^\dA-Za-z]/g, '').toUpperCase(),
      reg = new RegExp(".{" + after + "}", "g");
  return v.replace(reg, function (a) {
      return a + ' ';
  });
}

const insertSpace = (str, after) =>{
  after = after || 4;
  let reg = new RegExp(".{" + after + "}", "g");
  return str.replace(reg, function (a) {
    return a + ' ';
  });
}

const isHexadecimal = str => {
  return str.match(/[0-9A-Fa-f]/g);
}

const siteCodeError = errorType => {
  switch (errorType) {
    case 'contactAssistance':
      return 'Contact flow.support@emerson.com for assistance.';
    case 'tooFewChars':
      return 'Invalid Site Code – Not enough characters.';
    case 'notSupported':
      return 'Invalid Site Code – Software not supported.\nContact flow.support@emerson.com for assistance.';
    case 'invalid':
      return 'Invalid Site Code.';
    default:
      return '';
  }
}

const validateEmail = emailVal => {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emailVal)) {
    return true;
  } else {
    return false;
  }
};

/**
 * Export Methods
 */
export {
  commonValidation,
  validateEmail,
  space,
  insertSpace,
  isHexadecimal,
  siteCodeError
}