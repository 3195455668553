import { takeLatest, call, put } from 'redux-saga/effects';

/**
 * Import the constants variables
 */

import { 
    GET_GOODWILL_REPORT,
    GET_GOODWILL_REPORT_ERROR,
    GET_GOODWILL_REPORT_SUCCESS,
    GET_SITE_KEY_REPORT,
    GET_SITE_KEY_REPORT_ERROR,
    GET_SITE_KEY_REPORT_SUCCESS,
    UPDATE_QUANTITY,
    UPDATE_QUANTITY_SUCCESS,
    UPDATE_QUANTITY_ERROR,
    CLEAR_GOODWILL_REPORT,
    CLEAR_GOODWILL_REPORT_SUCCESS,
    CLEAR_GOODWILL_REPORT_ERROR,
    CLEAR_SITE_KEY_REPORT,
    CLEAR_SITE_KEY_REPORT_SUCCESS,
    CLEAR_SITE_KEY_REPORT_ERROR
} from './constants';

/**
 * Import Api
 */

import { goodWillApi, siteKeyApi, updateQuantityApi } from './api';

/**
 * action Type of GET_AUTH 
 * @param {Object} action
 */

function* goodWillSaga(action) {
  try {
    const {result} = yield call(goodWillApi, action.payload);
    const  data = JSON.parse(JSON.stringify(result).replace(/:null/gi, ":\"\""));
    yield put({ type: GET_GOODWILL_REPORT_SUCCESS, data });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_GOODWILL_REPORT_ERROR, error });
  }
}

/**
 * action Type of GET_AUTH 
 * @param {Object} action
 */

function* clearGoodWill(action) {
  try {
    const  data = []
    yield put({ type: CLEAR_GOODWILL_REPORT_SUCCESS, data });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: CLEAR_GOODWILL_REPORT_ERROR, error });
  }
}

/**
 * action Type of GET_AUTH 
 * @param {Object} action
 */

function* siteKeySaga(action) {
    try {
      const {result} = yield call(siteKeyApi, action.payload);
      const data = JSON.parse(JSON.stringify(result).replace(/:null/gi, ":\"\""));
      yield put({ type: GET_SITE_KEY_REPORT_SUCCESS, data });
    } catch (error) {
      // dispatch a failure action to the store with the error
      yield put({ type: GET_SITE_KEY_REPORT_ERROR, error });
    }
  }

/**
 * action Type of GET_AUTH 
 * @param {Object} action
 */

function* clearSiteKey(action) {
  try {
    const data = [];
    yield put({ type: CLEAR_SITE_KEY_REPORT_SUCCESS, data });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: CLEAR_SITE_KEY_REPORT_ERROR, error });
  }
}

/**
 * action Type UPDATE_QUANTITY
 * @param {Object} action
 */

 function* updateQuantity(action) {
   try{
    const data = yield call(updateQuantityApi, action.payload);
    yield put({ type: UPDATE_QUANTITY_SUCCESS, data });
   }catch(error){
    // dispatch a failure action to the store with the error
    yield put({ type: UPDATE_QUANTITY_ERROR, error });
   }
   
 }

/**
 * Get User Saga
 */

export function* getGoodWillReportSaga() {
  yield takeLatest(GET_GOODWILL_REPORT, goodWillSaga);
}

/**
 * Clear Good will Report Saga
 */

export function* clearGoodWillReportSaga() {
  yield takeLatest(CLEAR_GOODWILL_REPORT,clearGoodWill );
}

/**
 * Clear Site Key Report Saga
 */

export function* clearSiteKeyReportSaga() {
  yield takeLatest(CLEAR_SITE_KEY_REPORT,clearSiteKey );
}
/**
 * Get User Saga
 */

export function* getSiteKeyReportSaga() {
    yield takeLatest(GET_SITE_KEY_REPORT, siteKeySaga);
  }

  /**
 * Get User Saga
 */

export function* updateQuantitySaga() {
  yield takeLatest(UPDATE_QUANTITY, updateQuantity);
}

