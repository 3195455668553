import {
    INIT, SUCCESS, LOADING, ERROR,
  } from '../../utils/constants';

  /**
   * import constants 
   */

  import {
    GET_SEARCH_SERIAL_NUMBER_DATA, 
    GET_SEARCH_SERIAL_NUMBER_DATA_SUCCESS, 
    GET_SEARCH_SERIAL_NUMBER_DATA_ERROR,
    GET_REASON,
    GET_REASON_SUCCESS,
    GET_REASON_ERROR,
    GENERATE_GOODWILL,
    GENERATE_GOODWILL_SUCCESS,
    GENERATE_GOODWILL_ERROR
  } from './constants';
  
  /**
   * Initiliazed initila state of the store
   */

  const initialState = {
    phase: INIT,
    searchData: null,
    error: null,
    reason: [],
    goodwill: null
  };
  
  /**
   * Serail Number Reducer
   * Serail Store
   * @param {Object} state
   * @param {Object} action
   */

  export function serialNumber(state, action) {
    
    /**
     * State initilaization 
     */

    if (typeof state === 'undefined') {
      return initialState
    }

    /**
     * cases declaration for the action type
     * Loading 
     * Success
     * Error
     * All three phases are coverd
     */

    switch (action.type) {
      case GET_SEARCH_SERIAL_NUMBER_DATA: case GET_REASON: case GENERATE_GOODWILL:
        return {
          ...state,
          phase: LOADING,
        };
      case GET_SEARCH_SERIAL_NUMBER_DATA_SUCCESS:
        return {
          ...state,
          phase: SUCCESS,
          searchData: action.data,
          error: null,
        };
      case GET_SEARCH_SERIAL_NUMBER_DATA_ERROR: case GET_REASON_ERROR: case GENERATE_GOODWILL_ERROR:
        return { 
          ...state, 
          phase: ERROR, 
          error: action.error 
        };
      
      case GET_REASON_SUCCESS:
        return{
          ...state,
          phase:SUCCESS,
          error:null,
          reason: action.data
        }
      case GENERATE_GOODWILL_SUCCESS:
        return{
          ...state,
          phase:SUCCESS,
          error: null,
          goodwill: action.data
        }        
      default:
        return state;
    }
  }
  
  /**
   * Search serail number records by serail number
   * @param {String} email
   */
  
  export const searchData = payload => ({
    type: GET_SEARCH_SERIAL_NUMBER_DATA,
    payload,
  });



  /**
   * Get Reason Code Method
   * @param {*} payload 
   */
  export const getReasons = payload => ({
    type: GET_REASON,
    payload
  })

  /**
   * Generate Good will Licence
   */

   export const generateGoodWill = payload => ({
     type: GENERATE_GOODWILL,
     payload
   })