import React, { Component } from "react";
import {
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  Col,
  FormText,
  FormFeedback,
  Button,
  Modal,
  ModalHeader,
  ModalBody
} from "reactstrap";
import { debounce } from "debounce";
import PropTypes from "prop-types";
import {
  RestrictionType,
  RestrictionCount,
  NumberOfCopies,
  Options,
  Levels
} from "../../../utils/crypkeyConstants";
import {
  commonValidation,
  validateEmail,
  insertSpace,
  isHexadecimal,
  siteCodeError,
} from "../../../utils/methods"

class Customer extends Component {
  /**
   * Declare the Constructor and State variables.
   * @param {*} props
   */

  constructor(props) {
    super(props);
    this.state = {
      formValues: {},
      emailStatus: null,
      selectappStatus: null,
      sitecodeStatus: null,
      nameStatus: null,
      activationStatus: null,
      secondaryModal: false,
      serialNumberStatus: null,
      formToggle: "with",
      typeToggle: "send-site",
      reasonDisabled: false,
      siteCodeDisabled: false,
      commnetsDisabled: false,
      noActivationDisabled: true,
      reasonStatus: null,
      goodWillModal: false,
      errorModal: false,
      disabledSiteCode: true,
      errorMsg: []
    };
  }

  /**
   * Declare Static Proptype Types of the varaiables
   */

  static propTypes = {
    validateSerialKey: PropTypes.func.isRequired,
    validateSiteCodeCustomer: PropTypes.func.isRequired,
    generateSiteKey: PropTypes.func.isRequired,
    getReasons: PropTypes.func.isRequired,
    generateGoodWill: PropTypes.func.isRequired
  };

  /**
   * Formtoggle method is used to render the form
   * Activate with Serial Number And Site Code
   * Activate without Serial Number
   * Depends upon the fromtoggle value
   * if value is {with} it renders Activate with Serial Number And Site Code
   * else it renders Activate without Serial Number
   */

  formToggle = e => {
    const { formValues } = this.state;
    formValues.selectapp = "";
    this.setState({ formValues, selectappStatus: null });
    if (e.target.value === "with") {
      this.setState({ formToggle: e.target.value });
    } else {
      const { formValues } = this.state;
      formValues.serialnumber = "";
      formValues.sitecodevalue = "";
      formValues.selectapp= ""
      this.setState({
        formToggle: e.target.value,
        sitecodeStatus: null,
        serialNumberStatus: null,
        formValues: formValues,
        typeToggle: "send-site",
        selectappStatus: null
      });
    }
  };

  /**
   * Display the modal With error message
   * If the Serail Quantiy is less than zero
   */

  errorModal = () => {
    this.setState(prevState => ({
      errorModal: !prevState.errorModal
    }));
  };

  /**
   * selectType method is used to toggle between send site code form and send activation number
   * Its disabbled the form value on the basis of the typo selected
   * Its cleard the form values as well if usesr switch to next radio or
   */

  selectType = e => {
    if (e.target.value === "send-site") {
      const { formValues } = this.state;
      formValues.noofactivation = "";
      formValues.selectapp = ""
      this.setState({
        typeToggle: e.target.value,
        activationStatus: null,
        selectappStatus: null,
        formValues: formValues
      });
    } else {
      const { formValues } = this.state;
      formValues.sitecodevalue = "";
      formValues.comments = "";
      formValues.selectapp= "";
      this.setState({
        typeToggle: e.target.value,
        sitecodeStatus: null,
        reasonStatus: null,
        selectappStatus: null,
        formValues: formValues
      });
    }
  };

  /**
   * Validate Custoomer name
   */

  validateCustomer = customername => {
    if (customername) {
      this.setState({ nameStatus: false });
    } else {
      this.setState({ nameStatus: true });
    }
  };

  /**
   * Validate Softwrae type is used to validate the software type
   */

  validateSoftwareType = type => {
    const { translation: { data } } = this.props;
    if (type) {
      this.setState({ selectappStatus: false });
      const {
        userStore: { serial }
      } = this.props;
      const { formToggle } = this.state;
      if (formToggle === "with") {
        const selected = serial !== null ? serial.validate_data.find(
          selected => selected.software_type === type
        ): "";

        if (selected) {
          selected.license_qty > 0
          ? this.setState({ disabledSiteCode: false, errorMsg: [] })
          : this.setState({
              errorModal: true,
              errorMsg: selected ? selected.message[0] : ""
            });
        }else{
          const serialType = serial ? serial.validate_data[0].software_type : " ";
          const ErrorMessage = data ? `${data.error_1} ${type} ${data.error_2} ${serialType}${data.error_3}`: "";
          this.setState({
            errorModal: true,
            errorMsg: ErrorMessage,
          })
        }
      }
    } else {
      this.setState({ selectappStatus: true });
    }
  };

  /**
   * Validate the Activate count \
   * It should acceept only number between 1 to 10
   */

  validateActivateCount = number => {
    if (/^(?:[1-9]|10)$/.test(number)) {
      this.setState({ activationStatus: false });
    } else {
      this.setState({ activationStatus: true });
    }
  };

  /**
   * Validate the Reason Issue
   * It should not be empty
   */

  validateReason = reason => {
    if (reason) {
      this.setState({ reasonStatus: false });
    } else {
      this.setState({ reasonStatus: true });
    }
  };

  /**
   * handleChange method is used to handle the input change
   * And assigne the value to name
   */

  handleChange = e => {
    const { formValues } = this.state;
    if (e.target.name === "serialnumber") {
      formValues.selectapp = ""
      this.validateSerialNumber(e.target.value);
    }
    if (e.target.name === "sitecodevalue") {
      this.validateSite(e.target.value);
    }
    if (e.target.name === "email") {
      this.setState({emailStatus: !validateEmail(e.target.value)})
    }
    if (e.target.name === "customername") {
      this.validateCustomer(e.target.value);
    }
    if (e.target.name === "selectapp") {
      this.validateSoftwareType(e.target.value);
    }
    if (e.target.name === "activationcount") {
      this.validateActivateCount(e.target.value);
    }
    if(e.target.name === "reason"){
      this.validateReason(e.target.value);
    }
    formValues[e.target.name] = e.target.value;
    this.setState(() => ({ formValues }));
  };

  /**
   * handle the popup toggle function
   */

  secondaryModalToggle = () => {
    this.setState(prevState => ({
      secondaryModal: false
    }));
  };

  /**
   * handle goodWill popup Modal Function
   */

  goodWillModalToggle = () => {
    this.setState(prevState => ({
      goodWillModal: !prevState.goodWillModal
    }));
  };

  /**
   * Validate with function is used to validate the form fileds with assocatied serail Numbers
   */

  validateWith = () => {
    const valid = this.validateCommmonField();
    const {  translation: { data } } = this.props;
    const { crypKey : { siteCodeCustomer }, userStore: { serial }} = this.props;
    const { serialNumberStatus, sitecodeStatus, selectappStatus } = this.state;
    if (serialNumberStatus === null || serialNumberStatus === true) {
      this.setState({ serialNumberStatus: true });
    }
    if (selectappStatus === null || selectappStatus === true) {
      this.setState({ selectappStatus: true });
    }
    if (sitecodeStatus === null || sitecodeStatus === true) {
      this.setState({ sitecodeStatus: true });
    }
    if (
      valid === true &&
      sitecodeStatus === false &&
      serialNumberStatus === false &&
      selectappStatus === false
    ) {
      const selected = serial !== null ? serial.validate_data.find(
        selected => selected.software_type === siteCodeCustomer.software_type
      ): "";
      if(selected){
      selected.license_qty > 0
        ? this.generateCustomerLicence()
        : this.setState({
            errorModal: true,
            errorMsg: selected ? selected.message[0] : ""
          });
      }else{
        const siteCodeType = siteCodeCustomer ? siteCodeCustomer.software_type : " ";
        const serialType = serial ? serial.validate_data[0].software_type : " ";
        const ErrorMessage = data ? `${data.error_1} ${siteCodeType} ${data.error_2} ${serialType}${data.error_3}`: "";
        this.setState({
          errorModal: true,
          errorMsg: ErrorMessage ,
        })
      } 
    }
  };

  /**
   * validate forms filed assocated without serail number
   */

  validateWithout = () => {
    const valid = this.validateCommmonField();
    const {
      typeToggle,
      reasonStatus,
      sitecodeStatus,
      formValues,
      activationStatus,
      
    } = this.state;
    if (typeToggle === "send-site") {
      if (sitecodeStatus === null || sitecodeStatus === true) {
        this.setState({ sitecodeStatus: true });
      }
      if (formValues.reason) {
        this.setState({ reasonStatus: false });
      } else {
        this.setState({ reasonStatus: true });
      }
      if (
        valid === true &&
        sitecodeStatus === false &&
        reasonStatus === false
      ) {
        this.generateCustomerLicence();
      }
    } else {
      if (formValues.activationcount) {
        this.setState({ activationStatus: false });
      } else {
        this.setState({ activationStatus: true });
      }
      if (formValues.reason) {
        this.setState({ reasonStatus: false });
      } else {
        this.setState({ reasonStatus: true });
      }
      if (valid === true && activationStatus === false &&
        reasonStatus === false) {
        this.generateGoodWillLicence();
      }
    }
  };

  /**
   * validate Serial Number method is used to validate the serail number the user
   * Debounce function is used to prevent the api call
   */

  validateSerialNumber = debounce(serialNumber => {
    if (serialNumber) {
      const { validateSerialKey } = this.props;
      const body = {
        serial_number: serialNumber.replace(/\s/g, '')
      };
      validateSerialKey(body);
      this.setState({ serialNumberStatus: false });
    } else {
      this.setState({ serialNumberStatus: false });
    }
  }, 700);

  /**
   * validateSite Method is used to validate the sitecode
   * Debounce function is used to prvent the api call
   */

  validateSite = debounce(siteCode => {
    const { validateSiteCodeCustomer } = this.props;
    const { formValues } = this.state;
    let siteCodeArray = [];

    [...siteCode].forEach(c => {
      let letter = c.replace(/\s/g, '');
      letter = letter ? letter.toUpperCase() : ''
      letter = isHexadecimal(letter) ? letter : '';
      siteCodeArray.push(letter);
    })

    siteCode = siteCodeArray.join('').slice(0,18);
    siteCode = insertSpace(siteCode,4);

    if (siteCode.length === 22) {
      formValues.sitecodevalue = siteCode;
      this.setState({
        sitecodeStatus: false,
        formValues,
        errorTextSiteCode1: siteCodeError('invalid'),
        errorTextSiteCode2: siteCodeError('contactAssistance')
      });
      validateSiteCodeCustomer({site_code : siteCode});
    } else {
      formValues.sitecodevalue = siteCode;
      formValues.selectapp = "";
      this.setState({ sitecodeStatus: false, formValues });
    }
  }, 700);

  validateSiteOnBlur = e => {
    const { formValues } = this.state;
    if (e.target.value && e.target.value.length !== 22) {
      formValues.sitecodevalue = e.target.value;
      formValues.selectapp = "";
      this.setState({ sitecodeStatus: true, formValues });
      this.setState({
        errorTextSiteCode1: siteCodeError('tooFewChars'),
        errorTextSiteCode2: ''
      });
    }
  }

  /**
   * Vlaidate all the common field of forms exist between with and withou serail number
   */

  validateCommmonField = () => {
    const { formValues } = this.state;
    const status = commonValidation(formValues);
    this.setState({
      emailStatus: status.email,
      selectappStatus: status.selectapp,
      nameStatus: status.name
    });
    if (
      status.email === false &&
      status.name === false &&
      status.selectapp === false
    ) {
      return true;
    }
    return false;
  };

  /**
   * Generate Crypkey Customer Licnece Function
   */

  generateCustomerLicence = () => {
    const { formValues } = this.state;
    const { generateSiteKey } = this.props;
    let serialNumber = "",
      reason = "",
      comments = "";
    if (formValues.serialnumber) {
      serialNumber = formValues.serialnumber;
    }
    if (formValues.reason) {
      reason = formValues.reason;
    }
    if (formValues.comments) {
      comments = formValues.comments;
    }
    const body = {
      serial_number: serialNumber.replace(/\s/g, ''),
      email_id: formValues.email,
      site_code: formValues.sitecodevalue,
      software_type: formValues.selectapp,
      options: Options.STANDARD_OPTION,
      level: Levels.STANDARD_OPTION,
      numberOfCopies: NumberOfCopies.STANDARD_OPTION,
      restrictionType: RestrictionType.None,
      restrictionCount: RestrictionCount.STANDARD_OPTION,
      reason: reason,
      comments: comments,
      customer_name: formValues.customername,
      user_name: window.result? window.result.user_name : ""
    };
    generateSiteKey(body);
  };

  /**
   * Generate Good Will Licence Method
   */

  generateGoodWillLicence = () => {
    const { formValues } = this.state;
    const { generateGoodWill } = this.props;
    let reason = "", comments = "";
    if (formValues.reason) {
      reason = formValues.reason;
    }
    if (formValues.comments) {
      comments = formValues.comments;
    }
    const body = {
      num_of_activations: formValues.activationcount,
      customer_name: formValues.customername,
      software_type: formValues.selectapp,
      email_id: formValues.email,
      reason: reason,
      comments: comments
    };
    generateGoodWill(body);
  };

  /**
   * Component Life cycle is used to update the state and detetc the chages
   * @param {*} prevProps
   */
  checkSiteCode = (response) => {
    const {  formValues } = this.state;
    const appName = response ? response.software_type : "";
    if(appName){
      formValues["selectapp"] = response ? response.software_type : "";
      this.setState({ formValues})
      this.validateSoftwareType(appName)
    }
  }
  componentDidUpdate(prevProps) {
    const {
      userStore: { serial },
      crypKey: { siteCodeCustomer, siteKey },
      serialNumber: { goodwill }
    } = this.props;
    if (prevProps.crypKey.siteCodeCustomer !== siteCodeCustomer) {
      siteCodeCustomer.status === 200
        ? this.checkSiteCode(siteCodeCustomer)
        : this.setState({ sitecodeStatus: true ,formValues:{selectapp : ""}});
    }
    if (prevProps.crypKey.siteKey !== siteKey) {
      if (siteKey.status === 200){
        const { formValues } = this.state;
        formValues.serialnumber = "";
        formValues.sitecodevalue = ""
        this.setState({
            formValues,
            emailStatus: null,
            selectappStatus: null,
            sitecodeStatus: null,
            nameStatus: null,
            serialNumberStatus: null
          })}else{
        this.setState({ secondaryModal: false })}
    }
    if (prevProps.serialNumber.goodwill !== goodwill) {
      goodwill.status === 200
        ? this.setState({
            goodWillModal: true,
            formValues: {
              customername: "",
              email: "",
              selectapp: "",
              activationcount: "",
              reason: "",
              comments: ""
            },
            emailStatus: null,
            selectappStatus: null,
            activationStatus: null,
            nameStatus: null
          })
        : this.setState({ goodWillModal: false });
    }
    if (prevProps.userStore.serial !== serial) {




      serial.status === 200
        ? this.setState({ serialNumberStatus: false })
        : this.setState({ serialNumberStatus: true });
        if (serial.validate_data.length > 0) {
          this.setState({ disabledSiteCode: false });
      } else {
        this.setState({ disabledSiteCode: true });
      }


             if(siteCodeCustomer !== null && siteCodeCustomer !== undefined  && this.state.formValues.sitecodevalue !== "" &&
               this.state.formValues.sitecodevalue !== null &&  this.state.formValues.sitecodevalue !== undefined
             )
         {
           if (serial.validate_data.length > 0 && serial !== null) {
             siteCodeCustomer.status === 200
                 ? this.checkSiteCode(siteCodeCustomer)
                 : this.setState({sitecodeStatus: true});
           }
         }

    }
  }

  /**
   * rendering function rendering the JSX Elements.
   */
  render() {
    const {
      selectappStatus,
      formToggle,
      emailStatus,
      nameStatus,
      serialNumberStatus,
      sitecodeStatus,
      formValues,
      reasonStatus,
      activationStatus,
      typeToggle,
      errorMsg,
      disabledSiteCode,
      errorTextSiteCode1,
      errorTextSiteCode2
    } = this.state;
    const {
      translation: { data, software },
      serialNumber: { reason, goodwill }
    } = this.props;


    return (
      <>

        <Form autoComplete="off" data-test="customer">
          <Row className="cutomer">
            <Col sm="12">
              <FormGroup row>
                <Col sm={4}>
                  <Label for="exampleEmail" className="custom-label">
                    {data !== null ? "Customer Name*" : "Customer Name"}
                  </Label>
                  <Input
                    type="text"
                    name="customername"
                    id="customername"
                    className="form-input-text"
                    placeholder={
                      data !== null ? "Customer Name" : "Customer Name"
                    }
                    value={formValues.customername}
                    invalid={nameStatus}
                    valid={nameStatus === false ? true : false}
                    onChange={this.handleChange}
                  />
                  <FormFeedback invalid={"true"}>
                    {"Invalid Customer Name"}
                  </FormFeedback>
                  <FormText className="custom-text">
                    {data !== null
                      ? data.provide_customer_name
                      : "Provide Customer Name"}
                  </FormText>
                </Col>
                <Col sm={4}>
                  <Label for="exampleEmail" className="custom-label">
                    {data !== null
                      ? data.email_addr_label + "*"
                      : "Email Address"}
                  </Label>
                  <Input
                    type="email"
                    name="email"
                    id="emailAddress"
                    className="form-input-text"
                    placeholder={
                      data !== null ? data.email_addr_label : "Email Address"
                    }
                    value={formValues.email}
                    invalid={emailStatus}
                    valid={emailStatus === false ? true : false}
                    onChange={this.handleChange}
                  />
                  <FormFeedback invalid={"true"}>
                    {data !== null
                      ? data.email_error_msg
                      : "Invalid Email Address"}
                  </FormFeedback>
                  <FormText className="custom-text">
                    {data !== null ? data.email_addr_advanced_info : ""}
                  </FormText>
                </Col>
                {formToggle !== "with" && typeToggle !== "send-site" ? (
                  <Col sm={4}>
                    <Label
                      for="exampleEmail"
                      className="custom-label select-drop-label"
                    >
                      {data !== null
                        ? data.software_type + "*"
                        : "Software Type*"}
                    </Label>

                    <Input
                      type="select"
                      name="selectapp"
                      id="selectapp"
                      className="form-dropdown-text"
                      onChange={this.handleChange}
                      placeholder={
                        data !== null ? data.software_type : "Software Type"
                      }
                      invalid={selectappStatus}
                      valid={selectappStatus === false ? true : false}
                      value={formValues.selectapp}
                    >
                      <option value="">
                        {data !== null ? data.software_type : "Software Type"}
                      </option>
                      {software !==null ? software.map(appName => (
                        <option
                          value={appName.software_type}
                          key={appName.software_type}
                        >
                          {appName.software_type}
                        </option>
                      )): ""}
                    </Input>
                    <FormFeedback invalid={"true"}>
                      {"Please Select Software Type"}
                    </FormFeedback>
                    <FormText className="custom-text">
                      {data !== null
                        ? data.software_type_msg
                        : "Provide Software Type"}
                    </FormText>
                  </Col>
                ) : (
                  ""
                )}
              </FormGroup>
            </Col>
            <Col>
              <FormGroup row>
                <Col sm={4}>
                  <h2>
                    {data !== null
                      ? data.activation_type_heading
                      : "Activation Type"}
                  </h2>
                </Col>
                <Col sm={4}>
                  <Label className="radio-button">
                    {data !== null
                      ? data.activate_with_serial_num_radio_btn
                      : "Activate with Serial Number And Site Code"}
                    <Input
                      type="radio"
                      name="activation-type"
                      className="form-radio"
                      value={"with"}
                      onChange={this.formToggle}
                      defaultChecked
                    />{" "}
                    <span className="checkmark"></span>
                  </Label>
                </Col>
                <Col sm={4}>
                  <Label className="radio-button">
                    {data !== null
                      ? data.activate_without_serial_num_radio_btn
                      : "Activate without Serial Number"}
                    <Input
                      type="radio"
                      name="activation-type"
                      value={"without"}
                      onChange={this.formToggle}
                      className="form-radio"
                    />{" "}
                    <span className="checkmark"></span>
                  </Label>
                </Col>
              </FormGroup>
            </Col>
            {formToggle === "with" ? (
              <>
                <Col sm={12} className={"activation-type"}>
                  <FormGroup row>
                    <Col sm={4}>
                      <Label for="exampleEmail" className="custom-label">
                        {data !== null
                          ? data.serial_num_label + "*"
                          : "Serial Number"}
                      </Label>

                      <Input
                        type="text"
                        name="serialnumber"
                        id="serialNumber"
                        className="form-input-text"
                        placeholder={
                          data !== null
                            ? data.serial_num_label
                            : "Serial Number"
                        }
                        value={formValues.serialnumber}
                        onChange={this.handleChange}
                        invalid={serialNumberStatus}
                        valid={serialNumberStatus === false ? true : false}
                      />
                      <FormFeedback invalid={"true"}>
                        {data !== null
                          ? data.serial_num_error_msg
                          : "Serial Number"}
                      </FormFeedback>
                      <FormText className="custom-text">
                        {data !== null ? data.serial_num_info : ""}
                      </FormText>
                    </Col>
                    <Col sm={4}>
                      <Label for="exampleEmail" className="custom-label">
                        {data !== null
                          ? data.site_code_label + "*"
                          : "Site Code"}
                      </Label>
                      <Input
                        type="text"
                        name="sitecodevalue"
                        id="siteCode"
                        className="form-input-text"
                        placeholder={
                          data !== null ? data.site_code_label : "Site Code"
                        }
                        onChange={this.handleChange}
                        invalid={sitecodeStatus}
                        valid={sitecodeStatus === false}
                        value={formValues.sitecodevalue}
                        disabled={disabledSiteCode}
                        onBlur={this.validateSiteOnBlur}
                      />
                      <FormFeedback invalid={"true"}>
                        {errorTextSiteCode1}<br/>
                        {errorTextSiteCode2}
                      </FormFeedback>
                      <FormText className="custom-text">
                        {data !== null ? data.site_code_info : ""}
                      </FormText>
                    </Col>
                    <Col sm={4}>
                      <Label
                        for="exampleEmail"
                        className="custom-label"
                      >
                        {data !== null ? data.software_type + "*" : ""}
                      </Label>

                      <Input
                        type="text"
                        name="selectapp"
                        id="selectapp"
                        className="form-input-text"
                        onChange={this.handleChange}
                        placeholder={data !== null ? data.software_type : ""}
                        invalid={selectappStatus}
                        valid={selectappStatus === false ? true : false}
                        value={formValues.selectapp}
                        disabled={true}
                      >
                      </Input>
                      <FormFeedback invalid={"true"}>
                        {"Please Select Software Type"}
                      </FormFeedback>
                      <FormText className="custom-text">
                        {data !== null
                          ? data.software_type_msg
                          : "Provide Software Type"}
                      </FormText>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Col sm={4} >
                      <Button
                        className="btn-block primary-btn"
                        type="button"
                        onClick={this.validateWith}
                      >
                        {data !== null ? data.submit_btn+ " " : "SUBMIT "}
                        <span className="icon-style">
                          <i
                            className="fa fa-chevron-right hvr-icon"
                            aria-hidden="true"
                          ></i>
                        </span>
                      </Button>
                    </Col>
                  </FormGroup>
                </Col>
              </>
            ) : (
              <>
                <Col sm={12} className={"activation-type"}>
                  <FormGroup row>
                    <Col sm={5}>
                      <Label className="radio-button">
                        {data !== null
                          ? data.activate_send_site_key_radio_btn
                          : "Activate And Send Site key to Cutomer"}
                        <Input
                          type="radio"
                          name="radiotype"
                          className="form-radio"
                          value={"send-site"}
                          onChange={this.selectType}
                          defaultChecked
                        />{" "}
                        <span className="checkmark"></span>
                      </Label>
                    </Col>
                    <Col sm={6}>
                      <Label className="radio-button">
                        {data !== null
                          ? data.send_serial_number_radio_btn
                          : "Send Serial number used by Customer"}
                        <Input
                          type="radio"
                          name="radiotype"
                          className="form-radio"
                          value={"send-serial"}
                          onChange={this.selectType}
                        />{" "}
                        <span className="checkmark"></span>
                      </Label>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Col sm={4}>
                      <Label for="exampleEmail" className="custom-label">
                        {data !== null ? data.reason_label + "*" : "Reason"}
                      </Label>
                      <Input
                        type="select"
                        name="reason"
                        id="exampleSelect"
                        className={"form-input-text"}
                        placeholder={
                          data !== null ? data.reason_label : "Reason"
                        }
                        onChange={this.handleChange}
                        invalid={reasonStatus}
                        valid={reasonStatus === false ? true : false}
                        value={formValues.reason}
                      >
                        <option value="">{data !== null ? data.select_issue_reason : ""}</option>
                        {reason.map((record, i) => (
                          <option value={record} key={i}>
                            {record}
                          </option>
                        ))}
                      </Input>
                    </Col>
                    {typeToggle === "send-site" ? <>
                     <Col sm={4}>
                     <Label for="exampleEmail" className="custom-label">
                       {data !== null
                         ? data.site_code_label + "*"
                         : "Site Code"}
                     </Label>
                     <Input
                       type="text"
                       name="sitecodevalue"
                       id="siteCode"
                       className={"form-input-text"}
                       placeholder={
                         data !== null ? data.site_code_label : "Site Code"
                       }
                       onChange={this.handleChange}
                       invalid={sitecodeStatus}
                       valid={sitecodeStatus === false}
                       value={formValues.sitecodevalue}
                       onBlur={this.validateSiteOnBlur}
                     />
                     <FormFeedback invalid={"true"}>
                        {errorTextSiteCode1}<br/>
                        {errorTextSiteCode2}
                      </FormFeedback>
                      <FormText className="custom-text">
                        {data !== null ? data.site_code_info : ""}
                      </FormText>
                   </Col><Col sm={4}>
                    <Label
                      for="exampleEmail"
                      className="custom-label"
                    >
                      {data !== null
                        ? data.software_type + "*"
                        : "Software Type*"}
                    </Label>

                    <Input
                      type="text"
                      name="selectapp"
                      id="selectapp"
                      className="form-input-text"
                      onChange={this.handleChange}
                      placeholder={
                        data !== null ? data.software_type : "Software Type"
                      }
                      invalid={selectappStatus}
                      valid={selectappStatus === false ? true : false}
                      value={formValues.selectapp}
                      readOnly={true}
                    >
                    </Input>
                    <FormFeedback invalid={"true"}>
                    {data !== null? data.software_type_error: "Software Type Not Found"}
                    </FormFeedback>
                    <FormText className="custom-text">
                      {data !== null
                        ? data.software_type_msg
                        : "Provide Software Type"}
                    </FormText>
                  </Col></>
                    :
                    <Col sm={4}>
                    <Label for="exampleEmail" className="custom-label">
                      {data !== null
                        ? data.no_of_activations_label + "*"
                        : "# of Activation"}
                    </Label>
                    <Input
                      type="number"
                      name="activationcount"
                      id="noofactivation"
                      className={"form-input-text"}
                      placeholder={
                        data !== null
                          ? data.no_of_activations_label
                          : "# of Activation"
                      }
                      onChange={this.handleChange}
                      invalid={activationStatus}
                      valid={activationStatus === false ? true : false}
                      value={formValues.activationcount}
                    />
                    <FormFeedback invalid={activationStatus}>
                      {data !== null ? data.activation_1_to_10 : ""}
                    </FormFeedback>
                    </Col>
                  }
                   <Col sm={4}>
                      <Label for="exampleEmail" className="custom-label">
                        {data !== null ? data.comments_label : "Comments"}
                      </Label>
                      <Input
                        type="text"
                        name="comments"
                        id="comments2"
                        className={"form-input-text"}
                        placeholder={
                          data !== null ? data.comments_label : "Comments"
                        }
                        value={formValues.comments}
                        onChange={this.handleChange}
                      ></Input>
                    </Col>
                    <Col sm={4} className={"top-margin-input"}>
                    <Button
                        className="btn-block primary-btn "
                        type="button"
                        onClick={this.validateWithout}
                      >
                        {data !== null ? data.submit_btn+ " " : "SUBMIT "}
                         <i
                            className="fa fa-chevron-right hvr-icon"
                            aria-hidden="true"
                          ></i>
                      </Button>
                      </Col>
                    </FormGroup>
                      
                </Col>
                <Modal
                  isOpen={this.state.goodWillModal}
                  toggle={this.goodWillModalToggle}
                  className={this.props.className}
                  centered={true}
                >
                  <ModalHeader toggle={this.goodWillModalToggle} />
                  <ModalBody>
                    <Row>
                      <Col sm={"12"}>
                        <p>{goodwill !== null ? goodwill.message : ""}</p>
                        <p className="customerAdvancedActivation">
                         <b className = "fontWeight"> Serial Number :{" "}</b>
                          {goodwill !== null ? goodwill.serial_number : ""}
                        </p>
                      </Col>
                    </Row>
                  </ModalBody>
                </Modal>
              </>
            )}
            <Modal
              isOpen={this.state.errorModal}
              centered={true}
              className={this.props.className}
            >
              <ModalHeader toggle={this.errorModal} >
                {data !== null ? data.pop_up_header_1 : "ERROR"}
              </ModalHeader>
              <ModalBody >{errorMsg}</ModalBody>
            </Modal>
          </Row>
        </Form>
      </>
    );
  }
}

export default Customer;
