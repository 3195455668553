import Customer from "./Customer";
import { connect } from "react-redux";
import { validateSerialKey } from "../../../store/user";
import { validateSiteCodeCustomer, generateSiteKey } from "../../../store/crypkey";
import { getReasons, generateGoodWill } from "../../../store/serialnumber";

/**
 * Connect the Store State to the components
 * @param {*} state
 */
const mapStateToProps = state => state;

/**
 *  Dispatch the function for Api Calls
 */

const mapDispatchToProps = {
  validateSerialKey: payload => validateSerialKey(payload),
  validateSiteCodeCustomer: payload => validateSiteCodeCustomer(payload),
  generateSiteKey: payload => generateSiteKey(payload),
  getReasons: payload => getReasons(payload),
  generateGoodWill: payload => generateGoodWill(payload)
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Customer);
