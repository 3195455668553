import { takeLatest, call, put } from 'redux-saga/effects';
import {
    GET_SEARCH_SERIAL_NUMBER_DATA,
    GET_SEARCH_SERIAL_NUMBER_DATA_SUCCESS, 
    GET_SEARCH_SERIAL_NUMBER_DATA_ERROR,
    GET_REASON,
    GET_REASON_SUCCESS,
    GET_REASON_ERROR,
    GENERATE_GOODWILL,
    GENERATE_GOODWILL_SUCCESS,
    GENERATE_GOODWILL_ERROR
  } from './constants';
import { searchData, getReasonAPI, goodWillAPI } from './api';

/**
 * action Type of GET_USER
 * @param {Object} action
 */
function* serialData(action) {
  try {
    const data = yield call(searchData, action.payload);
    yield put({ type: GET_SEARCH_SERIAL_NUMBER_DATA_SUCCESS, data });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_SEARCH_SERIAL_NUMBER_DATA_ERROR, error });
  }
}

/**
 * Get User Saga
 */
export function* getSerialDataSaga() {
  yield takeLatest(GET_SEARCH_SERIAL_NUMBER_DATA, serialData);
}

/**
 * Get Reason Code Generator function 
 * Call  the api and set to the reducers
 * @param {*} action 
 */
function* getReasonCode(action){
  try {
    const {reason_code} = yield call(getReasonAPI, action.payload);
    const data = reason_code;
    yield put({ type: GET_REASON_SUCCESS, data });
  }catch(error){
    // dispatch a failure action to the store with the error
    yield put({ type: GET_REASON_ERROR, error });
  }
}

/**
 * Get Reason Saga
 * 
 */
export function* getReasonSaga(){
  yield takeLatest(GET_REASON,getReasonCode)
}

/**
 * Generate good will licence generator function
 * Call  the api and set to the reducers
 * @param {*} action 
 * 
 */

function* goodWill(action){
  try {
    const data = yield call(goodWillAPI, action.payload);
    yield put({ type: GENERATE_GOODWILL_SUCCESS, data });
  }catch(error){
    // dispatch a failure action to the store with the error
    yield put({ type: GENERATE_GOODWILL_ERROR, error });
  }
}

/**
 * Generate Good will Saga   
 */
export function* goodWillSaga(){
  yield takeLatest(GENERATE_GOODWILL,goodWill)
}
