import React from 'react';
import { LOADING } from '../../utils/constants';
import { connect } from 'react-redux';
import LoaderImage from '../../images/loading.gif';

/**
 * Create Function component
 * @param {*} props 
 */
function Loader(props) {
  /**
   * Compare the Loading phase with props if any exist the loading phase
   * then loader will enabled
   */

  if( 
      props.serialNumber.phase === LOADING || 
      props.translation.phase === LOADING || 
      props.userStore.phase === LOADING ||  
      props.crypKey.phase === LOADING ||
      props.reportStore.phase === LOADING
    ) {
    return (
       <>
        <div className="loader" ></div>
        <img src={LoaderImage} alt="loader" height={80} id="loader"/>
        
       </>
    );
  }
  return null;
}

/**
 * Connect the redxu state to props 
 * @param {*} param0 
 */

const mapStateToProps = ({translation, serialNumber,userStore, crypKey, reportStore }) => ({translation, serialNumber, userStore, crypKey, reportStore});

/**
 * Export the loader with states
 */
export default connect (mapStateToProps)(Loader);