

/**
 * Initiliazed Initial Constant which can be used as phase and mainitaining the state. 
 */
const INIT = 'INIT';
const LOADING = 'LOADING';
const SUCCESS = 'SUCCESS';
const ERROR = 'ERROR';
const API_URL = "/flow"

const METHOD = {
  POST: 'POST',
  GET: 'GET'
};
const ROOT = 'root'; 


/**
 * Export the initial varibale for Store and Reducers
 * API_URL
 * INIT
 * LOADING
 * SUCCESS
 * ERROR
 * KEY
 */

export {
  API_URL,
  INIT,
  LOADING,
  SUCCESS,
  ERROR,
  METHOD,
  ROOT
};