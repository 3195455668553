import React, { Component } from "react";
import { debounce } from "debounce";
import {
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  Col,
  FormText,
  FormFeedback,
  Button
} from "reactstrap";
import {
  RestrictionType,
  RestrictionCount,
  NumberOfCopies,
  Options,
  Levels
} from "../../../utils/crypkeyConstants";
import {
  space,
  validateEmail
} from "../../../utils/methods"

class Emerson extends Component {
  /**
   * Declare the Constructor and State variables.
   * @param {*} props
   */

  constructor(props) {
    super(props);
    this.state = {
      formValues: {
        reason: "Emerson Employee Activation"
      },
      emailStatus: null,
      selectappStatus: null,
      sitecodeStatus: null,
      secondaryModal: false
    };
  }

  /**
   * handleChange method is used to handle the input change
   * And assigne the value to name
   */


 /*to set initial mail */
    componentDidMount()
  {
   const  formValues = {
        email: window.result? window.result.mail_id : ""
      }

   this.setState({formValues})
    if(window.result)
    {
      this.setState({emailStatus: !validateEmail(window.result.mail_id)})
    }

  }

  handleChange = (e) => {
    const { formValues } = this.state;

    if (e.target.name === "email") {
      this.setState({emailStatus: !validateEmail(e.target.value)})
    }

    if (e.target.name === "selectapp") {
      this.validateSoftwareType(e.target.value);
    }

    if (e.target.name === "sitecodevalue") {
      this.validateSite(e.target.value);
      
    }
    
    formValues[e.target.name] = e.target.value;
    this.setState(() => ({ formValues }));
  };

  /**
   * validateSite Method is used to validate the sitecode
   * Debounce function is used to prvent the api call
   */

  validateSite = debounce(siteCode => {
    const { validateSiteCodeEmerson } = this.props;
    const { formValues } = this.state;
    siteCode = siteCode.replace(/\s/g, '').slice(0,18)
    siteCode = space(siteCode,4)
    if(siteCode.length === 22){
      formValues.sitecodevalue = siteCode
      this.setState({ sitecodeStatus: false, formValues })
      validateSiteCodeEmerson({site_code : siteCode});
    }else{
      formValues.sitecodevalue = siteCode
      this.setState({ sitecodeStatus: true, formValues });
    }
   
  }, 2000);

  /**
   * Validate Softwrae type is used to validate the software type
   */
  validateSoftwareType = type => {
    if (type) {
      this.setState({ selectappStatus: false });
    } else {
      this.setState({ selectappStatus: true });
    }
  };

  /**
   * validate form is used to validate the complete form fileds
   */
  validateForm = () => {
    const { emailStatus, selectappStatus, sitecodeStatus } = this.state;
    if (emailStatus === null || emailStatus === true) {
      this.setState({ emailStatus: true });
    } else if (selectappStatus === null || selectappStatus === true) {
      this.setState({ selectappStatus: true });
    } else if (sitecodeStatus === null || sitecodeStatus === true) {
      this.setState({ sitecodeStatus: true });
    } else {
      this.generateServiceLicence();
    }
  };

  /**
   * Generate Crypkey Service Licnece Function
   */

  generateServiceLicence = () => {
    const { formValues } = this.state;
    const { generateSiteKey } = this.props;
    const body = {
      serial_number: "",
      email_id: formValues.email ,
      site_code: formValues.sitecodevalue,
      software_type: formValues.selectapp,
      options: Options.SERVICE_lICENSE,
      level: Levels.STANDARD_OPTION,
      numberOfCopies: NumberOfCopies.STANDARD_OPTION,
      restrictionType: RestrictionType.None,
      restrictionCount: RestrictionCount.STANDARD_OPTION,
      reason: "Emerson Employee Activation",
      comments: formValues.comments || "",
      customer_name: "",
      user_name: window.result? window.result.user_name : ""
    };
    generateSiteKey(body);
  };

  /**
   * Component Life cycle is used to update the state and detetc the chages
   * @param {*} prevProps
   */

  componentDidUpdate(prevProps) {
    const {
      userStore: { email }
    } = this.props;
    const {
      crypKey: { siteCodeEmerson, siteKey }
    } = this.props;
    if (prevProps.userStore.email !== email) {
      email.status === 200
        ? this.setState({ emailStatus: false })
        : this.setState({ emailStatus: true });
    }
    if (prevProps.crypKey.siteCodeEmerson !== siteCodeEmerson) {
      const { formValues } = this.state;
      if(siteCodeEmerson.status === 200){
        formValues.selectapp = siteCodeEmerson.software_type;
        this.setState({ sitecodeStatus: false, formValues,selectappStatus:false })
      }else{
        formValues.selectapp = "";
        this.setState({ sitecodeStatus: true, formValues , selectappStatus:true})
      }
    }
    if (prevProps.crypKey.siteKey !== siteKey) {
      siteKey.status === 200
        ? this.setState({
            formValues: {
              sitecodevalue: "",
              comments: "",
              email: "",
              selectapp: ""
            },
            sitecodeStatus: null,
            selectappStatus: null,
            emailStatus: null
          })
        : this.setState({ secondaryModal: false });
    }
  }

  /**
   * rendering function rendering the JSX Elements.
   */
  render() {
    const {
      translation: { data }
    } = this.props;
    const {
      emailStatus,
      selectappStatus,
      sitecodeStatus,
      formValues
    } = this.state;
    return (
      <>
        <Form name={"EmersonCustomerForm"} autoComplete="off" data-test={'emerson'}>
          <Row className="cutomer">
            <Col sm="12">
              <FormGroup row>
                <Col sm={4}>
                  <Label for="exampleEmail" className="custom-label">
                    {data !== null
                      ? data.email_addr_label + "*"
                      : "Email Address"}
                  </Label>
                  <Input
                    type="email"
                    name="email"
                    id="exampleEmail"
                    className={"form-input-text"}
                    placeholder={
                      data !== null ? data.email_addr_label : "Email Address"
                    }
                    onChange={this.handleChange}
                    invalid={emailStatus}
                    valid={emailStatus === false ? true : false}
                    value={formValues.email}
                    defaultValue={window.result ? window.result.mail_id : null}
                  />

                  <FormFeedback invalid={"true"}>
                    {data !== null
                      ? data.email_error_msg
                      : "Invalid Email Address"}
                  </FormFeedback>
                  <FormText className="custom-text">
                    {data !== null ? data.email_addr_advanced_info : ""}
                  </FormText>
                </Col>
                <Col sm={4}>
                  <Label for="exampleEmail" className="custom-label">
                    {data !== null ? data.site_code_label + "*" : "Site Code"}
                  </Label>

                  <Input
                    type="text"
                    name="sitecodevalue"
                    id="siteCode"
                    className="form-input-text"
                    placeholder={
                      data !== null ? data.site_code_label : "Site Code"
                    }
                    onChange={this.handleChange}
                    invalid={sitecodeStatus}
                    valid={sitecodeStatus === false ? true : false}
                    value={formValues.sitecodevalue}
                  />
                  <FormFeedback invalid={"true"}>
                    {data !== null ? data.invalid_site_code : ""}
                  </FormFeedback>
                  <FormText className="custom-text">
                    {data !== null ? data.site_code_info : ""}
                  </FormText>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col sm={4}>
                  <Label
                    for="exampleEmail"
                    className="custom-label"
                  >
                    {data !== null ? data.software_type + "*" : ""}
                  </Label>

                  <Input
                    type="text"
                    name="selectapp"
                    id="selectapp"
                    className="form-input-text"
                    onChange={this.handleChange}
                    placeholder={data !== null ? data.software_type + "*" : ""}
                    invalid={selectappStatus}
                    valid={selectappStatus === false ? true : false}
                    value={formValues.selectapp}
                    readOnly={true}
                  >
                  </Input>
                  <FormFeedback invalid={"true"}>
                  {data !== null? data.software_type_error: "Software Type Not Found"}
                  </FormFeedback>
                  <br />

                  <Label for="exampleEmail" className="custom-label">
                    {data !== null ? data.reason_label : "Reason"}
                  </Label>
                  <Input
                    type="text"
                    name="reason"
                    id="serialNumber"
                    className="form-input-text"
                    placeholder={data !== null ? data.emerson_employee_activation : ""}
                    disabled
                    value={data !== null ? data.emerson_employee_activation : ""}
                  />
                </Col>
                <Col sm={4}>
                  <Label for="exampleEmail" className="custom-label">
                    {data !== null ? data.comments_label : "Comments"}
                  </Label>
                  <Input
                    type="textarea"
                    name="comments"
                    id="comments"
                    className="form-input-text"
                    placeholder={
                      data !== null ? data.comments_label : "Comments"
                    }
                    onChange={this.handleChange}
                    value={formValues.comments}
                  ></Input>
                </Col>
              </FormGroup>
            </Col>

            <Col sm={12}>
              <FormGroup row>
                <Col sm={{ size: 4 }} >
                  <Button
                    className="btn-block primary-btn generateSiteKeyFont"
                    type="button"
                    onClick={this.validateForm}
                  >
                    {data !== null
                      ? data.generate_site_key_btn
                      : "Generate Site Key"}{" "}
                    
                      <i className="fa fa-chevron-right hvr-icon" aria-hidden="true"></i>
                   
                  </Button>
                </Col>
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </>
    );
  }
}

export default Emerson;
