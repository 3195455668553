/**
 * import header component
 */

import Headers from './headers';

/**
 * export header component
 */

export default Headers;