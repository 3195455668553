/**
 * initilized the intial constants variable for Translation.
 */

const VALIDATE_SITE_CODE = 'VALIDATE_SITE_CODE';
const VALIDATE_SITE_CODE_SUCCESS = 'VALIDATE_SITE_CODE_SUCCESS';
const VALIDATE_SITE_CODE_ERROR = 'VALIDATE_SITE_CODE_ERROR';
const GENERATE_SITE_KEY = 'GENERATE_SITE_KEY';
const GENERATE_SITE_KEY_SUCCESS = 'GENERATE_SITE_KEY_SUCCESS';
const GENERATE_SITE_KEY_ERROR = 'GENERATE_SITE_KEY_ERROR';
const VALIDATE_SITE_CODE_EMERSON = 'VALIDATE_SITE_CODE_EMERSON';
const VALIDATE_SITE_CODE_SUCCESS_EMERSON = 'VALIDATE_SITE_CODE_SUCCESS_EMERSON';
const VALIDATE_SITE_CODE_CUSTOMER = 'VALIDATE_SITE_CODE_CUSTOMER';
const VALIDATE_SITE_CODE_SUCCESS_CUSTOMER = 'VALIDATE_SITE_CODE_SUCCESS_CUSTOMER';

/**
 * Export the Constants
 * It is used in the crypkey store as action type
 */

export {
  VALIDATE_SITE_CODE_CUSTOMER,VALIDATE_SITE_CODE_SUCCESS_CUSTOMER,VALIDATE_SITE_CODE_SUCCESS_EMERSON,VALIDATE_SITE_CODE_EMERSON,VALIDATE_SITE_CODE, VALIDATE_SITE_CODE_SUCCESS, VALIDATE_SITE_CODE_ERROR, GENERATE_SITE_KEY ,GENERATE_SITE_KEY_SUCCESS,GENERATE_SITE_KEY_ERROR
};