import { takeLatest, call, put } from 'redux-saga/effects';
import {   VALIDATE_SERIAL_SOFTWARE, VALIDATE_SERIAL_SOFTWARE_SUCCESS,VALIDATE_SERIAL, VALIDATE_SERIAL_SUCCESS, VALIDATE_SERIAL_ERROR} from './constants';
import { serialVerification} from './api';


/**
 * Validate Email is authenticated or not
 * Check it is exist in Database 
 * Email ID is required 
 * Saga Generator Function to call the API
 */

function* validateSerialNumber(action){
  try {
    const data = yield call(serialVerification,action.payload)
    yield put({ type: VALIDATE_SERIAL_SUCCESS, data})
  } catch (error) {
    yield put({ type: VALIDATE_SERIAL_ERROR, error })
  }
}


/**
* Validate Email Saga Function
*/

export function* validateSerialSaga(){
yield takeLatest(VALIDATE_SERIAL, validateSerialNumber)
}

/**
 * Validate Email is authenticated or not
 * Check it is exist in Database 
 * Email ID is required 
 * Saga Generator Function to call the API
 */

function* validateSerialNumberSoftware(action){
  try {
    const data = yield call(serialVerification,action.payload)
    yield put({ type: VALIDATE_SERIAL_SOFTWARE_SUCCESS, data})
  } catch (error) {
    yield put({ type: VALIDATE_SERIAL_ERROR, error })
  }
}


/**
* Validate Email Saga Function
*/

export function* validateSerialSoftwareSaga(){
yield takeLatest(VALIDATE_SERIAL_SOFTWARE, validateSerialNumberSoftware)
}
