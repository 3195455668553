import { takeLatest, call, put } from 'redux-saga/effects';
import { 
  VALIDATE_SITE_CODE, 
  VALIDATE_SITE_CODE_SUCCESS,
  VALIDATE_SITE_CODE_ERROR,
  GENERATE_SITE_KEY,
  GENERATE_SITE_KEY_SUCCESS,
  GENERATE_SITE_KEY_ERROR,
  VALIDATE_SITE_CODE_CUSTOMER,
  VALIDATE_SITE_CODE_SUCCESS_CUSTOMER,
  VALIDATE_SITE_CODE_SUCCESS_EMERSON,
  VALIDATE_SITE_CODE_EMERSON 
} from './constants';
import { validateSiteCodeApi,generateSiteCodeApi } from './api';



/**
 * Validate Site Code is authenticated or not
 * Check it is exist in Database 
 * Email ID is required 
 * Saga Generator Function to call the API
 */

 function* validateSite(action){
    try {
      const data = yield call(validateSiteCodeApi,action.payload)
      yield put({ type: VALIDATE_SITE_CODE_SUCCESS, data})
    } catch (error) {
      yield put({ type: VALIDATE_SITE_CODE_ERROR, error })
    }
 }

 function* validateSiteCustomer(action){
  try {
    const data = yield call(validateSiteCodeApi,action.payload)
    yield put({ type: VALIDATE_SITE_CODE_SUCCESS_CUSTOMER, data})
  } catch (error) {
    yield put({ type: VALIDATE_SITE_CODE_ERROR, error })
  }
}

function* validateSiteEmerson(action){
  try {
    const data = yield call(validateSiteCodeApi,action.payload)
    yield put({ type: VALIDATE_SITE_CODE_SUCCESS_EMERSON, data})
  } catch (error) {
    yield put({ type: VALIDATE_SITE_CODE_ERROR, error })
  }
}


 /**
  * Validate Email Saga Function
  */

export function* validateSiteCodeSaga(){
  yield takeLatest(VALIDATE_SITE_CODE, validateSite)
}
export function* validateSiteCodeCustomerSaga(){
  yield takeLatest(VALIDATE_SITE_CODE_CUSTOMER, validateSiteCustomer)
}
export function* validateSiteCodeEmersonSaga(){
  yield takeLatest(VALIDATE_SITE_CODE_EMERSON, validateSiteEmerson)
}


/**
 * Validate Site Code is authenticated or not
 * Check it is exist in Database 
 * Email ID is required 
 * Saga Generator Function to call the API
 */

function* generateSite(action){
  try {
    const data = yield call(generateSiteCodeApi,action.payload)
    yield put({ type: GENERATE_SITE_KEY_SUCCESS, data})
  } catch (error) {
    yield put({ type: GENERATE_SITE_KEY_ERROR, error })
  }
}


/**
* Validate Email Saga Function
*/

export function* generateSiteCodeSaga(){
yield takeLatest(GENERATE_SITE_KEY, generateSite)
}

