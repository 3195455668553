
import Reports from './Reports';
import { connect } from 'react-redux';

/**
 * Connect the Store State to the components
 * @param {*} state 
 */
const mapStateToProps = state => state;

/**
 *  Dispatch the function for Api Calls
 */

const mapDispatchToProps = {};
/**
 * Export Report Conatiner
 */

export default connect(mapStateToProps, mapDispatchToProps)(Reports);
