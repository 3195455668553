import axios from 'axios';
import { API_URL, METHOD } from '../../utils/constants';

/**
 * Make an Api call for authorization of the user
 * @param {*} payload 
 */
export const searchData = payload =>{
  return axios({
    method: METHOD.POST,
    url: `${API_URL}/search_serial_num/`,
    headers: { 'Content-Type': 'application/json' },
    data: JSON.stringify(payload),
  });
}

/**
 * Make an Api call for authorization of the user
 * @param {*} payload 
 */
export const getReasonAPI = payload =>{
  return axios({
    method: METHOD.POST,
    url: `${API_URL}/reason_data/`,
    headers: { 'Content-Type': 'application/json' },
    data: JSON.stringify(payload),
  });
}

/**
 * Make an Api call for authorization of the user
 * @param {*} payload 
 */
export const goodWillAPI = payload =>
  axios({
    method: METHOD.POST,
    url: `${API_URL}/goodwill_generation/`,
    headers: { 'Content-Type': 'application/json' },
    data: JSON.stringify(payload),
  });
