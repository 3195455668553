import React, { Component } from "react";
import { Headers } from "../../components";
import {Button, Col, Container, Row} from "reactstrap";



/**
 * Main Container which is controlling all the container and components
 * All four conatier are called here for fours tabs
 * Tab toggling fuctionality are implemented here
 *
 */

class Error extends Component {
  /**
   * Constructors initilization
   * @param {*} props
   */

  constructor(props) {
    super(props);
    this.state={
      language : "en_US"
    }

  }

  componentDidMount() {
    const { getTranslation } = this.props;
    const { language } = this.state;

    getTranslation({ language : language});

  }


   componentDidUpdate(prevProps, prevState) {
    const {
     getTranslation
    } = this.props;
    const { language } = this.state;
    if(prevState.language !== language){
      getTranslation({ language : language});
    }
  }

  /**
   * Toggle page across login and logout
   * @param {*} tab on click the value of the tab
   */
   goBack =()=>{

  this.props.history.push('/');
}

onLanChange = (lang) =>{
    this.setState({
      language: lang
    });
  }


  render() {

        const {
      translation: { data }
    } = this.props;

    return (
      <>

           <Headers languageChange={this.onLanChange} error = {true} />
           <Container>
          <Row className="heading-row" data-test="home">
            {/*<Col lg = "6">*/}
            <Col sm="12" >
                {  data !== null ?   <h1 className="fontInfo">{data.login_error_1} <a href="https://www.emerson.com/en-us" target="blank" className="fontAnchor">{data.login_error_2}</a> {data.login_error_3}</h1> : null}

            </Col>
                <Col sm="12" md={{ size: 4, offset: 4 }}>

                    {  data !== null ?     <Button
                type="button"
                className="primary-btn btn-block error-btn-margin"
                onClick={this.goBack}
              >
                        {data.back_btn}
              </Button> : null}
            </Col>
          </Row>
           </Container>


      </>
    );
  }
}

export default Error;
