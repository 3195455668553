/**
 * initilized the intial constants variable for serail numbers.
 */

const GET_SEARCH_SERIAL_NUMBER_DATA = 'GET_SEARCH_SERIAL_NUMBER_DATA';
const GET_SEARCH_SERIAL_NUMBER_DATA_SUCCESS = 'GET_SEARCH_SERIAL_NUMBER_DATA_SUCCESS';
const GET_SEARCH_SERIAL_NUMBER_DATA_ERROR = 'GET_SEARCH_SERIAL_NUMBER_DATA_ERROR';
const GET_REASON = 'GET_REASON';
const GET_REASON_SUCCESS = 'GET_REASON_SUCCESS';
const GET_REASON_ERROR = 'GET_REASON_ERROR';
const GENERATE_GOODWILL = 'GENERATE_GOODWILL';
const GENERATE_GOODWILL_SUCCESS = 'GENERATE_GOODWILL_SUCCESS';
const GENERATE_GOODWILL_ERROR = 'GENERATE_GOODWILL_ERROR';

/**
 * Export the Constants
 * It is used in the serial number store as action type
 * GET_SEARCH_SERIAL_NUMBER_DATA   
 * GET_SEARCH_SERIAL_NUMBER_DATA_SUCCESS
 * GET_SEARCH_SERIAL_NUMBER_DATA_ERROR
 * GET_REASON
 * GET_REASON_SUCCESS
 * GET_REASON_ERROR
 */

export {
    GET_SEARCH_SERIAL_NUMBER_DATA, 
    GET_SEARCH_SERIAL_NUMBER_DATA_SUCCESS, 
    GET_SEARCH_SERIAL_NUMBER_DATA_ERROR,
    GET_REASON,
    GET_REASON_SUCCESS,
    GET_REASON_ERROR,
    GENERATE_GOODWILL,
    GENERATE_GOODWILL_SUCCESS,
    GENERATE_GOODWILL_ERROR
};