import React from  'react';
import { Route, HashRouter as Router ,Switch} from 'react-router-dom';
import { Home } from './container';
import { Loader } from './components';
import  Error  from "./container/Error";
/**
 * Creates global Routes for the components with authorized and non-authorized
 *  <Route  path="/:sid?/:active_org_id?" component={Home} />
 */
const Routes = () => (

    <Router>
        <>
            <Loader />
            <Switch>
            <Route  path ="/error" component={Error}/>
            <Route  exact path="/:sid?/:active_org_id?" component={Home} />
            </Switch>
        </>
    </Router>

)

export default Routes;