/**
 * Import React Library and Required Files and method
 */

import React, { Component } from "react";
import { Row, FormGroup, Label, Input, Col } from "reactstrap";
import Customer from "./customer";
import Emerson from "./emerson";

/**
 * Advance Activation Class based Component
 */

class AdvancedActivation extends Component {
  /**
   * Declare the Constructor and State variables.
   * @param {*} props
   */
  constructor(props) {
    super(props);
    this.state = {
      type: "Customer"
    };
  }

  /**
   * onSelect Method is used to handel the change event of customer type whether
   * It is Emplyee customer or Customer
   */

  onSelectType = (e) => {
    this.setState({
      type: e.target.value
    });
  };

  /**
   * rendering function rendering the JSX Elements.
   */

  render() {
    /**
     * Deconstructing of the Object and variables
     */

    const { type } = this.state;
    const {
      translation: { data }
    } = this.props;

    /**
     * Return Function
     */

    return (
      <>
        <Row className="row-tab" data-test="activation-tab">
          <Col sm={12}>
            <h2>
              {data !== null ? data.activation_heading : "Activation Tool"}
            </h2>
            <FormGroup row>
              <Label for="exampleEmail" sm={3} className="radio-button-label">
                {data !== null
                  ? data.generate_lic_for_label
                  : "Generate License For"}
              </Label>
              <Col sm={3}>
                <Label className="radio-button">
                  {data !== null
                    ? data.emerson_emp_radio_btn
                    : "Emerson Employee"}
                  <Input
                    type="radio"
                    name="match"
                    className="form-radio"
                    value={"Emerson"}
                    onClick={this.onSelectType}
                    data-test="emerson-type"
                  />
                  <span className="checkmark"></span>
                </Label>
              </Col>

              <Col sm={2}>
                <Label className="radio-button">
                  {data !== null ? data.customer_radio_btn : "Customer"}
                  <Input
                    type="radio"
                    name="match"
                    className="form-radio"
                    value={"Customer"}
                    onClick={this.onSelectType}
                    defaultChecked
                    data-test="customer-type"
                  />{" "}
                  <span className="checkmark"></span>
                </Label>
              </Col>
            </FormGroup>
          </Col>
        </Row>
        {type === "Customer" ? <Customer /> : <Emerson />}
      </>
    );
  }
}

/**
 * Export the AdvancedActivation class contaier with default method
 * Connect the mapsatetoprops
 * Connect map dispatchtoprops
 * Import Api Redcuers and function
 */

export default AdvancedActivation;
