import React from "react";

/**
 * Instruction fuction Component
 * display the instruction message from the props
 * 
 * display the instruction message from the props
 */
const Instruction = (props) => {
  
  /**
   * Getting the value from the props
   */

  const { transData } = props;
  
  /**
   * return the jsx elements and render to the main component 
   */
  return (
    <>
      <h2> {transData !==null? transData.instructions_label :'Instructions'} </h2>
      <ul>
        <li>
          <p>{transData !==null? transData.instruction_list1 : ''}</p>
        </li>
        <li>
        <p>{transData !==null? transData.instruction_list2 : ''}</p>
        </li>
        <li>
        <p>{transData !==null? transData.instruction_list3 : ''}</p>
        </li>
        <li>
        <p> {transData !==null? transData.instruction_list4 : ''}</p>
        </li>
        <li>
        <p> {transData !==null? transData.instruction_list5 : ''}</p>
        </li>
        <li>
        <p>{transData !==null? transData.instruction_list6 : ''}</p>
        </li>
      </ul>
    </>
  );
};

/**
 * Export the Instruction Function
 */
export default Instruction;
