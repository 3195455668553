import {
    INIT, SUCCESS, LOADING, ERROR,
  } from '../../utils/constants';
  import {
    GET_AUTH,
    GET_AUTH_ERROR,
    GET_AUTH_SUCCESS
  } from './constants';
  
  /**
   * Initiliazed initila state of the store
   */
  const initialState = {
    phase: INIT,
    auth: null,
    error: null,
  };
  
  /**
   * User Reducer
   * @param {Object} state
   * @param {Object} action
   */
  
  export function authStore(state, action) {
    /**
     * Set the initial state value
     */

    if (typeof state === 'undefined') {
      return initialState
    }

    /**
     * Cases for the action type
     */

    switch (action.type) {
      case GET_AUTH:
        return {
          ...state,
          phase: LOADING,
        };
      case GET_AUTH_SUCCESS:
        return {
          ...state,
          phase: SUCCESS,
          auth: action.data,
          error: null,
        };
      case GET_AUTH_ERROR:
        return { 
          ...state, 
          phase: ERROR, 
          error: action.error 
        };
     
      default:
        return state;
    }
  }
  
  /**
   * Get Auth function
   * @param {String} email
   */
  
  export const getAuth = payload => ({
    type: GET_AUTH,
    payload,
  });
  
 