import axios from 'axios';

/**
 * import environment variables
 */

import { API_URL ,  METHOD } from '../../utils/constants';

/**
 * Make an Api call for serialVerification 
 * @param {*} credentials 
 */
export const serialVerification = serialNumber =>
  axios({
    method: METHOD.POST,
    url: `${API_URL}/validate_serial_num/`,
    headers: { 'Content-Type': 'application/json'},
    data: JSON.stringify(serialNumber),
})