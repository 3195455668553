import { all } from "redux-saga/effects";
import { validateSerialSaga,validateSerialSoftwareSaga } from "./user/saga";
import { getSerialDataSaga, getReasonSaga,goodWillSaga } from "./serialnumber/saga";
import { getTranslationSaga, softwareTypeSaga } from "./translation/saga";
import { validateSiteCodeSaga,generateSiteCodeSaga,validateSiteCodeCustomerSaga,validateSiteCodeEmersonSaga } from "./crypkey/saga";
import { getUserAuthSaga } from "./auth/saga";
import { getGoodWillReportSaga , getSiteKeyReportSaga, updateQuantitySaga,clearGoodWillReportSaga,clearSiteKeyReportSaga } from './report/saga';

/**
 * Yield all the saga generators functions
 */

export default function* (){
    yield all([
        getSerialDataSaga(),
        getTranslationSaga(),
        validateSerialSaga(),
        validateSiteCodeSaga(),
        generateSiteCodeSaga(),
        getReasonSaga(),
        getUserAuthSaga(),
        goodWillSaga(),
        getGoodWillReportSaga(),
        getSiteKeyReportSaga(),
        updateQuantitySaga(),
        softwareTypeSaga(),
        clearGoodWillReportSaga(),
        clearSiteKeyReportSaga(),
        validateSerialSoftwareSaga(),
        validateSiteCodeCustomerSaga(),
        validateSiteCodeEmersonSaga()
    ])
}