import { takeLatest, call, put } from 'redux-saga/effects';

/**
 * Import the constants variables
 */

import { 
    GET_AUTH,
    GET_AUTH_ERROR,
    GET_AUTH_SUCCESS
} from './constants';

/**
 * Import Api
 */

import { authApi } from './api';

/**
 * action Type of GET_AUTH 
 * @param {Object} action
 */

function* authSaga(action) {
  try {
    
    // call the api
    
    const data = yield call(authApi, action.payload);
    
    // Store the value to localstorage

    yield localStorage.setItem('persist:root', JSON.stringify({
      phase: 'SUCCESS',
      user: JSON.stringify(data),
      error: null,
    }));
    yield put({ type: GET_AUTH_SUCCESS, data });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_AUTH_ERROR, error });
  }
}

/**
 * Get User Saga
 */

export function* getUserAuthSaga() {
  yield takeLatest(GET_AUTH, authSaga);
}

