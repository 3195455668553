import { connect } from "react-redux";
import SoftwareActivation from "./SoftwareActivation";
import {validateSerialKeySoftware } from "../../store/user";
import { validateSiteCode, generateSiteKey } from "../../store/crypkey";

/**
 * Connect the Store State to the components
 * @param {*} state
 */
const mapStateToProps = state => state;

/**
 *  Dispatch the function for Api Calls
 */

const mapDispatchToProps = {
  
  validateSiteCode: payload => validateSiteCode(payload),
  generateSiteKey: payload => generateSiteKey(payload),
  validateSerialKeySoftware: payload => validateSerialKeySoftware(payload)
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SoftwareActivation);
