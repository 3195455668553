import {
    INIT, SUCCESS, LOADING, ERROR,
  } from '../../utils/constants';
  import {
    GET_GOODWILL_REPORT,
    GET_GOODWILL_REPORT_ERROR,
    GET_GOODWILL_REPORT_SUCCESS,
    GET_SITE_KEY_REPORT,
    GET_SITE_KEY_REPORT_ERROR,
    GET_SITE_KEY_REPORT_SUCCESS,
    UPDATE_QUANTITY,
    UPDATE_QUANTITY_SUCCESS,
    UPDATE_QUANTITY_ERROR,
    CLEAR_GOODWILL_REPORT,
    CLEAR_GOODWILL_REPORT_SUCCESS,
    CLEAR_GOODWILL_REPORT_ERROR,
    CLEAR_SITE_KEY_REPORT,
    CLEAR_SITE_KEY_REPORT_SUCCESS,
    CLEAR_SITE_KEY_REPORT_ERROR
  } from './constants';
  
  /**
   * Initiliazed initila state of the store
   */
  const initialState = {
    phase: INIT,
    goodwillreport: null,
    sitekeyreport: null,
    updateQuantity: null,
    error: null,
  };
  
  /**
   * User Reducer
   * @param {Object} state
   * @param {Object} action
   */
  
  export function reportStore(state, action) {
    /**
     * Set the initial state value
     */

    if (typeof state === 'undefined') {
      return initialState
    }

    /**
     * Cases for the action type
     * Loading 
     * Success
     * Error
     * All three phases are coverd
     */

    switch (action.type) {
      case GET_GOODWILL_REPORT: case GET_SITE_KEY_REPORT: case UPDATE_QUANTITY: case CLEAR_GOODWILL_REPORT: case CLEAR_SITE_KEY_REPORT:
        return {
          ...state,
          phase: LOADING,
        };
      case GET_SITE_KEY_REPORT_SUCCESS: case CLEAR_SITE_KEY_REPORT_SUCCESS:
        return {
          ...state,
          phase: SUCCESS,
          sitekeyreport: action.data,
          error: null,
        };
      case GET_GOODWILL_REPORT_SUCCESS: case CLEAR_GOODWILL_REPORT_SUCCESS:
            return {
              ...state,
              phase: SUCCESS,
              goodwillreport: action.data,
              error: null,
            };
      case UPDATE_QUANTITY_SUCCESS:
            return{
              ...state,
              phase: SUCCESS,
              updateQuantity: action.data,
              error: null
            }        
      case GET_GOODWILL_REPORT_ERROR: case GET_SITE_KEY_REPORT_ERROR: case UPDATE_QUANTITY_ERROR: case CLEAR_GOODWILL_REPORT_ERROR: case CLEAR_SITE_KEY_REPORT_ERROR:
        return { 
          ...state, 
          phase: ERROR, 
          error: action.error 
        };
     
      default:
        return state;
    }
  }
  
  /**
   * Get Good will report function
   * @param {String} payload
   */
  
  export const getGoodWillReport = payload => ({
    type: GET_GOODWILL_REPORT,
    payload,
  });

  /**
   * Get Good will report function
   * @param {String} payload
   */
  
  export const getSiteKeyReport = payload => ({
    type: GET_SITE_KEY_REPORT,
    payload,
  });

  /**
   * Update Quantity of the Activation Number
   */

  export const updateQuantityNumber = payload => ({
    type: UPDATE_QUANTITY,
    payload
  })

  /**
   * Clear Good will report function
   * @param {String} payload
   */
  
  export const clearGoodWillReport = payload => ({
    type: CLEAR_GOODWILL_REPORT,
    payload,
  });
  

  /**
   * Clear SIte Site report function
   * @param {String} payload
   */
  
  export const clearSiteKeyReport = payload => ({
    type: CLEAR_SITE_KEY_REPORT,
    payload,
  });
  
 