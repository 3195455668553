/**
 * Combine rootReducer and rootSagas and Create Store
 */
import { createStore, applyMiddleware, compose  } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createLogger } from 'redux-logger';
import { persistStore } from 'redux-persist';
import sagas from './rootSagas';
import reducers from './rootReducer';

const middleWare = [];

/**
 * Initiliazed Saga Middleware
 */

const sagaMiddleware = createSagaMiddleware();
middleWare.push(sagaMiddleware)

/**
 * Initiliazed Logger Tools
 */
const logger = createLogger({
  // eslint-disable-next-line no-undef
  predicate: () => process.env.NODE_ENV === 'development',
});
middleWare.push(logger)

/**
 * Create Store and combine Saga and Logger middlewares
 */

const store = createStore(
  reducers,
  compose( applyMiddleware(...middleWare)),
 
);
sagaMiddleware.run(sagas);

/**
 * Create Persistor for the store
 */

const persistor = persistStore(store);

export {
  store,
  persistor,
};
